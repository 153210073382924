import React from 'react';
import { User } from 'lib/classes';
import { AutoAdvanceButton, FixedSizeQuestion, FlowSubtext, FullWidthNextButton, autoAdvanceSetValue } from 'components/FlowForm';
import { AsyncSpinnerButton, FlowAnswerButton, UnderlineText } from 'components/Button';
import * as _ from 'lib/utilities';
import { ExtraExclusions } from './UserFields';
import groceryBreeze from 'assets/img/grocery_breeze.webp';
import { CalculatingScreen, Illustration, PointListItem, ReachGoalByScreen, filterSubmitValues } from 'partials/OnboardingComponents';
import { CheckButtons, SelectButton, WeekdayButtonSelect } from 'components/Form';
import SummaryCard from './OnboardingSummaryCard';
import { MultipleSelectWheel } from 'components/SelectWheel';
import { Link } from 'components/Routing';
import { brandName, citations } from 'config/settings';
import moment from 'moment';
import { MealInfoMatrix } from 'lib/meal-info-matrix';
import OffPlanMealPlanTable from 'components/OffPlanMealTable';
import RecipePickFlowPage from './RecipePickFlowPage';

export const BeforeMealPlanScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("beforempscreenmsg")} />
            <FlowSubtext>
                {t('beforempscreensub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} user={user} highlight="mealPlan" reachGoalPace="slow" />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

export const FoodExclusionsQ = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('foodstoexcludeq')} />
            <FlowSubtext className="mb30">
                {t("foodstoexcludesub")}
            </FlowSubtext>
            <div className="text-left">
                <ExtraExclusions t={t} values={flowProps.values} setFieldValue={flowProps.setFieldValue} />
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const lunchTypes = [
    "Sandwich or wrap",
    "Salad or mixed bowl",
    "Protein with sides",
    "Fast food",
    "Other"
];

export const LunchTypeQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('typicallunchq')} />
            {lunchTypes.map((lunchType,i) => {

                return (
                    <AutoAdvanceButton key={lunchType} {...flowProps} attr="lunchStyle" value={i} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`lunch-${i}-btn`}>
                            <span>{t(lunchType)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const FastingQ = ({ flowProps, t }) => {
    const yesNoClick = value => () => {
        const { values, advance, setValues } = flowProps;
        const newValues = { 
            ...values, 
            fastStartRemind: (value === 'No' ? '' : 6*60), 
            fastStartRemindPm: true, 
            fastEndRemind: (value === 'No' ? '' : 8*60), 
            fastEndRemindPm: false,
            tryIfAnswer: value
        };
        setValues(newValues);
        return setTimeout(() => advance({ ...newValues }),1);
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('tryinterfastq')} />
            {['Learn more'].map((tryIf,i) => {

                return (
                    <AutoAdvanceButton key={tryIf} {...flowProps} attr="tryIfAnswer" value={tryIf} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`tryif-${i}-btn`}>
                            <span>{t(tryIf)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
            <FlowAnswerButton color="primary" rounded onClick={yesNoClick('Yes')} id={`tryif-${1}-btn`}>
                <span>{t('Yes')}</span>
            </FlowAnswerButton>
            <FlowAnswerButton color="primary" rounded onClick={yesNoClick('No')} id={`tryif-${2}-btn`}>
                <span>{t('No')}</span>
            </FlowAnswerButton>
        </React.Fragment>
    )
}

const fastingPoints = [
    { icon: 'info-circle', title: 'iflearnmore1lbl', desc: 'iflearnmore1desc' },
    { icon: 'question-circle', title: 'iflearnmore2lbl', desc: 'iflearnmore2desc' }
]

export const FastingLearnScreen = ({ flowProps, t }) => {
    const questionText = <React.Fragment>
            {t("iflearnmoremsg")}
            <Link target="_blank" to={citations.intermittentFasting}><sup>1</sup></Link>
        </React.Fragment>

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30 mt0 pt0" text={questionText} />
            <div>
                <div className="onb-ol">
                    {fastingPoints.map(({ icon, title, desc }) => {

                        return (
                            <PointListItem icon={icon}>
                                <label>{t(title)}:</label> {t(desc)}
                            </PointListItem>
                        )
                    })}
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Got It')}
            />
        </React.Fragment>
    )
}

const FastRemindSelect = ({ flowProps, stub, t }) => {

    return (
        <MultipleSelectWheel
            propsArr={[
                { ...flowProps, 
                    name: `${stub}Remind`, 
                    options: User.workoutRemindCol(t) ,
                    height: 150
                }, 
                { ...flowProps, 
                    name: `${stub}RemindPm`, 
                    options: User.workoutRemindPmCol(t),
                    className: 'smaller',
                    height: 150
                }]
            }
        />
    )
}

export const FastingBreakQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("ifstartq")} />
            <FlowSubtext className="mb30">
                {t("ifstartsub")}
            </FlowSubtext>
            <FastRemindSelect flowProps={flowProps} stub={'fastEnd'} t={t} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

export const FastingStartQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("ifendq")} />
            <FlowSubtext className="mb30">
                {t("ifendsub")}
            </FlowSubtext>
            <FastRemindSelect flowProps={flowProps} stub={'fastStart'} t={t} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

export const MealTypesQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('mealtypesq')} />
            <FlowSubtext className="mb30">{t("Pick at least",{count: 2})}</FlowSubtext>
            <div>
                <CheckButtons {...flowProps} name="selectedMealTypes" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {User.MEAL_TYPES.map(([mt,label]) => (
                                <SelectButton 
                                    id={`select-${mt}-btn`} 
                                    key={mt} 
                                    toggleHandler={toggleHandler} 
                                    value={mt} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(label)}</span>
                                </SelectButton>                            
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

export const MealPlanStartDateQ = ({ flowProps, t }) => {
    const { values, setFieldValue, advance, setFieldTouched } = flowProps;
    
    return (
        <React.Fragment>
                <FixedSizeQuestion className text={t("mealplanwdayq")} />
                <FlowSubtext className="mb30">{t("mealplanwdaysub")}</FlowSubtext>
                <WeekdayButtonSelect 
                    startDay={0} 
                    name="mealPlanWeekday"
                    values={values} 
                    setFieldValue={autoAdvanceSetValue({ setFieldValue, values, advance })}
                    setFieldTouched={setFieldTouched}
                    dayNames={moment.weekdaysShort()}
                    single
                />
        </React.Fragment>
    )
}

export const WeekendsEatMoreQ = ({ flowProps, t, setCalorieSchedule }) => {
    const yesAction = highDays => () => {
        const { values, setValues, advance } = flowProps;
        const newValues = { 
            ...values, 
            highDays,
            flexibleWeekends: true
        };
        setValues(newValues);
        return advance(newValues);
    }

    const noAction = () => {
        const { values, setValues } = flowProps;
        const newValues = { 
            ...values, 
            highDays: [''],
            flexibleWeekends: false
        };
        setValues(newValues);
        return setCalorieSchedule({ ...filterSubmitValues(newValues), highDays: [''] });
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('weekdayspecnutq')} />
            <FlowSubtext className="mb30">
                {t('weekdayspecnutsub')}
            </FlowSubtext>
            {[[5,6,0],[5,6],[6,0]].map((highDays,i) => {

                return (
                    <FlowAnswerButton key={i} color="primary" rounded onClick={yesAction(highDays)} id={`weekends-${i}-btn`}>
                        <span>{t('eatmoreweekdays', { weekdays: _.weekdaysToSentence(highDays) })}</span>
                    </FlowAnswerButton>
                )
            })}
            <AsyncSpinnerButton
                buttonProps={{
                    className: 'flow-answer-btn',
                    noShadow: true,
                    color: 'primary',
                    label: t('noilleatsame')
                }}
                successCallback={() => flowProps.advance(flowProps.values)}
                icon={''}
                action={noAction}
            />
        </React.Fragment>
    )
}

export const WeekendsEatMoreSuccess = ({ user, flowProps, t, setCalorieSchedule }) => {
    const { values: { highDays } } = flowProps;
    const { high, low } = user.highAndLowCalDayGoals();

    const [loaded,setLoaded] = React.useState(false);

    if(!loaded) {
        return (
            <CalculatingScreen 
                duration={4}
                subtitles={[t('Shifting calories around'),t('Adjusting meal plan')]}
                subtitleSwitchPcts={[35]}
                successCallback={() => setLoaded(true)}
                load={() => setCalorieSchedule({ ...filterSubmitValues(flowProps.values), highDays })}
            />
        )
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('ratiolifestyleset', { ratio: `${7-highDays.length}:${highDays.length}`})} />
            <FlowSubtext className="mb30">
                {t("ratiolifestylesub")}
            </FlowSubtext>
            <div>
                <div className="onb-ol">
                    <PointListItem icon={['far','pizza-slice']}>
                        <label>{_.weekdaysToSentence(highDays)}:</label> {t("youllbeeating",{ calories: high })}
                    </PointListItem>
                    <PointListItem icon={['far','salad']}>
                        <label>{_.weekdaysToSentence(_.difference(_.weekdays(),highDays))}:</label> {t("youllbeeating",{ calories: low })}
                    </PointListItem>
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Continue')}
            />
        </React.Fragment>
    )
}

export const OffPlanMealsQ = ({ user, flowProps, t, setOffPlanMeals }) => {
    const [offPlanMealMap,setOffPlanMealMap] = React.useState(MealInfoMatrix.blankOffPlanMap(user));
    const setOffPlanType = (mtId,date,value) => setOffPlanMealMap({ ...offPlanMealMap, [mtId]: { ...offPlanMealMap[mtId], [date]: value } });
    const noneSelected = _.every(_.flatMap(Object.values(offPlanMealMap),mtObj => Object.values(mtObj)),offPlanType => (offPlanType === 0));
    const allSelected = _.every(_.flatMap(Object.values(offPlanMealMap),mtObj => Object.values(mtObj)),offPlanType => (offPlanType === 2));
    const toggleAll = () => {
        const newVal = allSelected ? 0 : 2;
        setOffPlanMealMap(MealInfoMatrix.blankOffPlanMap(user,newVal));
    }
    const mtMap = _.keyBy(user.planMealTypes(),'id');

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30 mt0 pt0" text={t('typicaloffplanq')} />
            <OffPlanMealPlanTable offPlanMealMap={offPlanMealMap} setOffPlanType={setOffPlanType} mtMap={mtMap} t={t} />
            <div className="mt50">
                <UnderlineText label={allSelected ? t('Deselect All') : t('Select All')} onClick={toggleAll} />
            </div>
            {noneSelected && (<FullWidthNextButton 
                flowProps={flowProps}
                outlined={true}
                label={t("Skip")}
            />)}
            {!noneSelected && (
                <AsyncSpinnerButton
                    buttonProps={{
                        className: 'btn-full-width btn-bottom-center ff-next-btn',
                        color: 'primary',
                        label: t('Next'),
                        id: `submit-off-plan-btn`
                    }}
                    successCallback={() => flowProps.advance(flowProps.values)}
                    icon={''}
                    action={() =>  setOffPlanMeals({ offPlanMeals: offPlanMealMap, startDate: user.mealPlanStartDateFor('current') })}
                />
            )}
        </React.Fragment>
    )
}

export const SecondGoalByScreen = ({ flowProps, t }) => {
    const { values: { similarUsers2 } } = flowProps;

    return (
        <ReachGoalByScreen
            flowProps={flowProps}
            t={t}
            pace="moderate"
            title={t("rgb2msg")}
            subtextRender={({ endDate }) => (
                <React.Fragment>
                    <FlowSubtext className="text-left mb10 ml20 mt15">{t('rgb2text1',{ num: _.numberWithCommas(similarUsers2), brand_name: brandName(), date: endDate.format('MMM Do') })}</FlowSubtext>
                    <FlowSubtext className="text-left mb10 ml20">{t('rgb2text2')}</FlowSubtext>
                </React.Fragment>
            )}
        />
    )
}

export const ShareMealsQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('sharemealsq')} />
            <FlowSubtext className="mb30">{t("sharemealsqsub")}</FlowSubtext>
            <div>
                <CheckButtons {...flowProps} name="familyTypes" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {['Partner','Roommates','Children'].map((type) => (
                                <SelectButton 
                                    id={`select-${type}-btn`} 
                                    key={type} 
                                    toggleHandler={toggleHandler} 
                                    value={type} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(type)}</span>
                                </SelectButton>                            
                            ))}
                            <AutoAdvanceButton {...flowProps} attr="familyTypes" value={['']} render={({ onClick }) => (
                                <FlowAnswerButton color="grey" className="flow-answer-btn btn-select" outlined rounded onClick={onClick} id={`no-family-btn`}>
                                    <span>{t('No')}</span>
                                </FlowAnswerButton>
                            )} />
                        </React.Fragment>
                    )
                }}/>

            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const FamilyCountsQ = ({ type, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('howmanyshareq', {object: _.lowerFirst(type === 'children' ? t('Children') : t('Roommates'))})} />
            {[1,2,3,4,5,6].map((count) => {

                return (
                    <AutoAdvanceButton key={count} {...flowProps} attr={`${type}Count`} value={count} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`count-${count}-btn`}>
                            <span>{count}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const ChildrenCountQ = (props) => (<FamilyCountsQ {...props} type="children" />)
export const RoomatesCountQ = props => (<FamilyCountsQ {...props} type="roommates" />)

export const SharedMealTypesQ = ({ flowProps, t }) => {
    const { values: { selectedMealTypes } } = flowProps;
    
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('sharedmtsq')} />
            <div>
                <CheckButtons {...flowProps} name="sharedMealTypes" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {selectedMealTypes.map((mt) => (
                                <SelectButton 
                                    id={`select-${mt}-btn`} 
                                    key={mt} 
                                    toggleHandler={toggleHandler} 
                                    value={mt} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(_.capitalize(mt))}</span>
                                </SelectButton>                            
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

export const PeopleAddedToPlanSuccess = ({ user, flowProps, t, initMiniProfiles }) => {
    const { values: { familyTypes, childrenCount, roommatesCount, sharedMealTypes } } = flowProps;
    const submitVals = {};

    if(familyTypes.includes('Partner')) {
        submitVals.partnerCount = 1;
    }

    if(childrenCount) {
        submitVals.childrenCount = childrenCount;
    }

    if(roommatesCount) {
        submitVals.roommatesCount = roommatesCount;
    }

    if(!_.isEmpty(submitVals)) {
        submitVals.sharedMealTypes = sharedMealTypes;
    }

    const [loaded,setLoaded] = React.useState(false);

    if(!loaded) {
        return (
            <CalculatingScreen 
                duration={4}
                subtitles={[t('addingppltoplan')]}
                subtitleSwitchPcts={[]}
                successCallback={() => setLoaded(true)}
                load={!_.isEmpty(submitVals) ? (() => initMiniProfiles(submitVals)) : null}
            />
        )
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('ppladdedsuccessmsg')} />
            <FlowSubtext className="mb30">
                {t("ppladdedsuccess")}
            </FlowSubtext>
            <Illustration src={groceryBreeze} width={480} height={480} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Continue')}
            />
        </React.Fragment>
    )
}

export const RecipePickerStep = ({ startMealPlan, flowProps, ...props }) => {

    return (
        <RecipePickFlowPage 
            {...props}
            flowProps={flowProps}
            forNewWeek="current"
            continueBtnRender={({ user, t, initialRecipeIds, forNewWeek }) => {

                return  (
                    <AsyncSpinnerButton
                        buttonProps={{
                            className: 'btn-full-width btn-bottom-center ff-next-btn',
                            color: 'primary',
                            label: t('Generate'),
                            id: `submit-recsel-btn`
                        }}
                        successCallback={() => flowProps.advance(flowProps.values)}
                        icon={'random'}
                        action={() => {
                            return startMealPlan(initialRecipeIds,user.mealPlanStartDateFor(forNewWeek))
                        }}
                    />
                )
            }}
        />
    )
}

export const AfterMealPlanScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("mealplanreadymsg")} />
            <FlowSubtext>
                {t('mealplanreadysub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} user={user} highlight="mealPlan" reachGoalPace="moderate" />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}