import React, {useState} from 'react';
import Typography from 'components/Typography';
import Button from "components/Button";
import Collapse from 'components/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LoginForm.module.scss';
import EmailLoginForm, { EmailSignupForm, TCEmailSignupForm, TrainerEmailSignupForm } from 'components/EmailLoginForm';
import OAuthButtons, { SignupOAuthButtons, TrainerSignupOAuthButtons } from 'components/OAuthButtons';
import { FlowQuestion } from 'components/FlowForm';
import { useTranslation } from 'react-i18next';
import { brandName } from 'config/settings';
import { homePath, startPath, switchAccountPath, switchAccountPathFor, trainerHomePath, webForgotPasswordPath } from 'config/paths';
import { Link } from 'components/Routing';
import * as _ from 'lib/utilities';
import { TSIGNUP_CONTEXTS } from 'config/settings';
import { resolvedStartPath } from 'redux/helpers';


export function SignupForm({ successPath, context }) {
    const { t } = useTranslation();
    const [curStatus,setCurStatus] = useState('initial');
    const emailMissing = curStatus === 'emailMissing';
    const title = emailMissing ? t("Provide an email") : t("Make an account");
    const subtitle = emailMissing ? t("email missing message",{oauth_type: 'Facebook'}) : t("make an account description")
    const isTrainerSignup = TSIGNUP_CONTEXTS.includes(context);
    const EmailForm = isTrainerSignup ? TrainerEmailSignupForm : EmailSignupForm;
    const OAuthForm = isTrainerSignup ? TrainerSignupOAuthButtons : SignupOAuthButtons;
    const str = isTrainerSignup ? "Sign in to continue" : "Sign In"

    if(_.allowDirectSignup()) {
        const hasNonEmailOption = (_.isPrimaryDomain() || _.isIOS());

        return (
            <React.Fragment>
                    <FlowQuestion text={title} className="no-margin" />
                    {curStatus !== 'tos' && (<p className="thin-subtitle" style={{ minWidth: '250px' }}>{isTrainerSignup ? '' : subtitle}</p>)}
                    {curStatus === 'initial' && <EmailOption label={t('Continue with type',{login_type: 'email'})} startExpanded={!hasNonEmailOption} contentRender={() => (
                        <EmailForm label={t('Next')} successPath={successPath} />
                    )}/>}
                    {hasNonEmailOption && (<OAuthForm 
                        label={t('Next')} 
                        emailMissing={emailMissing} 
                        setCurStatus={setCurStatus} 
                        curStatus={curStatus} 
                        successPath={successPath} 
                        onlyApple={!_.isPrimaryDomain()} 
                    />)}
                    {curStatus === 'initial' && (<div className="mt20">
                        <p className="thin-subtitle">{t("Already have an account?")} <Link id="switch-account-link" to={_.isBlank(successPath) ? switchAccountPath : switchAccountPathFor(encodeURIComponent(successPath))} replace>{t(str)}</Link></p>
                    </div>)}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <FlowQuestion text={title} className="no-margin" />
                <EmailForm label={t('Next')} successPath={successPath} />
                <div className="mt20">
                    <p className="thin-subtitle">{t("Already have an account?")} <Link id="switch-account-link" to={_.isBlank(successPath) ? switchAccountPath : switchAccountPathFor(encodeURIComponent(successPath))} replace>{t(str)}</Link></p>
                </div>
        </React.Fragment>
    )

}

export function InviteForm({ successPath, context }) {
    const { t } = useTranslation();
    const title = t("Make an account");

    return (
        <React.Fragment>
                <FlowQuestion text={title} className="no-margin" />
                <p className="thin-subtitle" style={{ minWidth: '250px' }}></p>
                <TCEmailSignupForm label={t('Next')} successPath={successPath} />
        </React.Fragment>
    )
}

export default function LoginForm({ user, redirectTo, isSwitcher }) {
    const { t } = useTranslation();
    const defSuccessPath = (user && user.isTrainer()) ? trainerHomePath : homePath();
    const successPath = _.isBlank(redirectTo) ? defSuccessPath : decodeURIComponent(redirectTo);
    
    return (
        <div style={{textAlign: 'center', minWidth: '300px', maxWidth: '90vw'}}>
            <Typography variant='h2'>{t('Sign in to brand name',{brand_name: brandName()})}</Typography>
            <div className={styles.container}>
                <EmailOption startExpanded={!_.allowDirectSignup()} label={t('Sign in with type',{login_type: 'email'})} contentRender={() => (<EmailLoginForm label={t('Sign In')} successPath={successPath} isSwitcher={isSwitcher} />)}/>
                {_.allowDirectSignup() && (<OAuthButtons successPath={successPath} isSwitcher={isSwitcher} onlyApple={!_.isPrimaryDomain()} />)}
                <div >
                    {_.allowDirectSignup() && !isSwitcher && (<div>{t("Don't have an account?")} <Link to={resolvedStartPath()}>{t("Sign Up")}</Link></div>)}
                    <div><Link to={webForgotPasswordPath()}>{t('Forgot your password?')}</Link></div>
                </div>
            </div>
        </div>
    )
}

function EmailOption({ label, contentRender, startExpanded=false }) {
    const [ expanded, setExpanded ] = useState(startExpanded)

    return (
        <div className="mb10">
            <Button id="email-login-btn" outlined color="primary" rounded block onClick={() => { setExpanded(expanded ? false : true )} }>
                <FontAwesomeIcon icon="at" size='1x' className='em-1-5' /><span>{label}</span>
            </Button>
            <Collapse isOpen={expanded}>
                {contentRender()}
            </Collapse>
        </div>
    )
}






