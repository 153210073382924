import BlankErrorBoundary from 'components/BlankErrorBoundary';
import React, { Suspense, lazy } from 'react';

// Lazy-load the component
const BarcodeScanner = lazy(() => import('./BarcodeScanner'));

const BarcodeScannerWrapper = (props) => {

    return (<BlankErrorBoundary>
        <Suspense fallback={''}>
            <BarcodeScanner {...props} />
        </Suspense>
    </BlankErrorBoundary>)
}

export default BarcodeScannerWrapper;