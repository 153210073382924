import React from 'react';
import { AutoAdvanceButton, FixedSizeQuestion, FlowSubtext, FullWidthNextButton } from 'components/FlowForm';
import { FlowAnswerButton } from 'components/Button';
import { Illustration } from 'partials/OnboardingComponents';
import SummaryCard from './OnboardingSummaryCard';
import { brandName, dateFormat } from 'config/settings';
import { ProgOverloadChart, progOvrDiffDelay, progOvrPerfDelay } from './EstimatedProgressChart';
import { FadeIn } from 'components/FadeIn';
import superhero from 'assets/img/superhero.webp';
import yesCardio from 'assets/img/yes_cardio.webp';
import noCardio from 'assets/img/no_cardio.webp';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import moment from 'moment';

export class BeforeTrainingProfileScreen extends React.Component {

    constructor(props) {
        super(props);
        const { loadBannableProgs } = this.props;
        loadBannableProgs().catch(() => console.log("Failed to load bannable progressions."));
    }

    render() {
        const { user, flowProps, t } = this.props;
        
        return (
            <React.Fragment>
                <FixedSizeQuestion text={t("trainingprofilemsg")} />
                <FlowSubtext>
                    {t('trainingprofilesub')}
                </FlowSubtext>
                <SummaryCard flowProps={flowProps} t={t} user={user} highlight="training" reachGoalPace="moderate" />
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t("Continue")}
                />
            </React.Fragment>
        )
    }
}

const trainingTypes = [
    "I want a plan/guidance",
    "I want a flexible daily step goal",
    "I just want help with nutrition"
]

export const TrainingTypeQ = ({ flowProps, t }) => {
    const clickHandler = (i) => () => {
        const { values, advance, setValues } = flowProps;
        const newValues = { 
            ...values, 
            noRoutine: i !== 0,
            dailyStepGoal: null,
            trainingType: i
        };

        if(i !== 0) {
            newValues.routineStart = moment().format(dateFormat);
        }
        setValues(newValues);
        return advance({ ...newValues });
    }
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('howexq')} />
            {trainingTypes.map((trainingType,i) => {

                return (
                    <FlowAnswerButton key={i} color="primary" rounded onClick={clickHandler(i)} id={`training-${i}-btn`}>
                        <span>{t(trainingType)}</span>
                    </FlowAnswerButton>
                )
            })}
        </React.Fragment>
    )
}

export const SetStepGoalQ = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('stepgoalq')} />
            {[5000,7500,10000].map((stepGoal,i) => {

                return (
                    <AutoAdvanceButton key={stepGoal} {...flowProps} attr="dailyStepGoal" value={stepGoal} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`step-${i}-btn`}>
                            <span>{`${t(['Easy','Moderate','Challenging'][i])} (${t('stepsday', { steps: stepGoal })})`}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )    
}

const dyrStatements = [
    "I find it hard to choose the right exercises for myself",
    "I find exercises too hard and give up easily",
    "I find weight lifting to be intimidating",
    "I feel demotivated by a lack of results"
]

const DyrQ = ({ flowProps, t, i }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb0" text={t('dyrtos')} />
            <FixedSizeQuestion className="mb30 mt15 pt0" text={`"${t(dyrStatements[i])}"`} />
            {['Yes','Maybe a little','No'].map((agreeType,i) => {

                return (
                    <AutoAdvanceButton key={agreeType} {...flowProps} attr={`dyrQuestion${i}`} value={agreeType} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`dyr-${i}-btn`}>
                            <span>{t(agreeType)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )    
}

export const DyrQOne = (props) => (<DyrQ {...props} i={0} />)
export const DyrQTwo = (props) => (<DyrQ {...props} i={1} />)
export const DyrQThree = (props) => (<DyrQ {...props} i={2} />)
export const DyrQFour = (props) => (<DyrQ {...props} i={3} />)

const ProgressiveOverloadIllustration = ({ t, type }) => {

    return (
        <Illustration>
            <ProgOverloadChart t={t} type={type} />
            <div className="title">{type === 'overload' ? t('strtstpluspover') : t('tplansfailover')}</div>
            <FadeIn delay={progOvrPerfDelay}>
                <div className={classnames("rdiets-label",{ "wrk-prf": type === 'overload', "wrk-prf2": type !== 'overload' })}>
                    {t("Your workout performance")}
                </div>
            </FadeIn>
            <FadeIn delay={progOvrDiffDelay}>
                <div className={classnames("rdiets-label",{ "wrk-dif": type === 'overload', "wrk-dif2": type !== 'overload' })}>
                    {t("Workout difficulty")}
                </div>
            </FadeIn>
            <div className="bot-axis-lbl ll">{t('Day X', { day: 1 })}</div>
            <div className="bot-axis-lbl rl">{t('Day X', { day: 30 })}</div>
        </Illustration>
    )
}

export const ProgressiveOverloadScreen = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb20 mt0 pt0" text={t('howtplanworks')} />
            <ProgressiveOverloadIllustration t={t} type="overload" />
            <FlowSubtext className="text-left mb10 ml10 mt15">{t('strtstpoint1')}</FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">{t('strtstpoint2')}</FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">{t('strtstpoint3')}</FlowSubtext>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Got It")}
            />
        </React.Fragment>
    )
}

export const NoProgOverloadScreen = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb20 mt0 pt0" text={t('howtplansdontwork')} />
            <ProgressiveOverloadIllustration t={t} type="noOverload" />
            <FlowSubtext className="text-left mb10 ml10 mt15">{t('tplansfailsub1')}</FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">{t('tplansfailsub2')}</FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">{t('tplansfailsub3')}</FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">{t('brandisdifferent', { brand_name: brandName() })}</FlowSubtext>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Got It")}
            />
        </React.Fragment>
    )
}

export const AnyStrengthTrainQ = ({ flowProps, t }) => {
    const noClick = () => {
        const { values, advance, setValues } = flowProps;
        const newValues = { 
            ...values, 
            anyStrengthTraining: false,
            liftingExperience: 0
        };
        setValues(newValues);
        return advance({ ...newValues });
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('anystrtrnq')} />
            <FlowSubtext className="mb30">{t('strtrndef')}</FlowSubtext>
            <AutoAdvanceButton {...flowProps} attr="anyStrengthTraining" value={true} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id={`training-yes-btn`}>
                    <span>{t('Yes')}</span>
                </FlowAnswerButton>
            )} />
            <FlowAnswerButton color="primary" rounded onClick={noClick} id={`training-no-btn`}>
                <span>{t('No')}</span>
            </FlowAnswerButton>
        </React.Fragment>
    )
}

const trainingHabits = {
    "trnhabit1": 2,
    "trnhabit2": 1,
    "trnhabit3": 0,
    "trnhabit4": 0
}

const liftingExpArr = [2,1,0,0]

export const TrainingExpQ = ({ flowProps, t }) => {
    const clickHandler = i => () => {
        const { values, advance, setValues } = flowProps;
        const newValues = { 
            ...values, 
            anyStrengthTraining: true,
            trainingHabits: i,
            liftingExperience: liftingExpArr[i]
        };
        setValues(newValues);
        return advance({ ...newValues });
    }
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('trainingexpq')} />
            {Object.entries(trainingHabits).map(([str,val],i) => {
                return (
                    <FlowAnswerButton key={i} color="primary" rounded onClick={clickHandler(i)} id={`training-exp-${i}-btn`}>
                        <span>{t(str)}</span>
                    </FlowAnswerButton>
                )
            })}
        </React.Fragment>
    )
}

export const BeginnerSuperpowerScreen = ({ flowProps, t }) => {
    const { values: { liftingExperience } } = flowProps;

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={liftingExperience >=1 ? t('intermediateissuper') : t('beginnerissuper')} />
            <FlowSubtext className="text-left mb10 ml10">
                {t("beginnersupersub1")}
            </FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">
                <Trans i18nKey="beginnersupersub2"><b></b></Trans>
            </FlowSubtext>
            <Illustration src={superhero} width={480} height={480} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Continue')}
            />
        </React.Fragment>
    )
}

const sleepAmounts = [
    "5 hours or less",
    "5 to ~6 hours",
    "7 to ~8 hours",
    "More than 8 hours"
]

export const SleepAmountQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('sleepq')} />
            {sleepAmounts.map((sleepAmount,i) => {

                return (
                    <AutoAdvanceButton key={sleepAmount} {...flowProps} attr="sleepAmount" value={i} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`training-${i}-btn`}>
                            <span>{t(sleepAmount)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const EnjoyCardioQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('enjoycardioq')} />
            {['Yes','No'].map((yesNo,i) => {

                return (
                    <AutoAdvanceButton key={yesNo} {...flowProps} attr="needsCardio" value={yesNo === 'Yes'} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`cardio-${i}-btn`}>
                            <span>{t(yesNo)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const CardioInfoScreen = ({ flowProps, t }) => {
    const { values: { needsCardio, weightGoal } } = flowProps;
    let goalStr = 'lose';
    if(weightGoal === 1) {
        goalStr = 'gain';
    } else if(weightGoal === 0) {
        goalStr = 'tone';
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={needsCardio ? t('cardiogreatmsg') : t('cardiobadmsg')} />
            <FlowSubtext className="text-left mb10 ml10">
                {needsCardio ? t("cardiogreatsub") : t('cardiobadsub')}
            </FlowSubtext>
            {!needsCardio && (<FlowSubtext className="text-left mb10 ml10">
                <Trans i18nKey={`cardiobad${goalStr}`}><b></b></Trans>
            </FlowSubtext>)}
            <Illustration src={needsCardio ? yesCardio : noCardio} width={480} height={480} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Continue')}
            />
        </React.Fragment>
    )
}

export const AfterTrainingProfileScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("aftertprofilemsg")} />
            <FlowSubtext>
                {t('aftertprofilesub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} user={user} highlight="training" reachGoalPace="moderate" />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}