import React from "react";
import * as _ from 'lib/utilities';
import classnames from 'classnames';

export const MultipleSelectWheel = ({ propsArr }) => {

    return (
        <div className="multi-wheel-cont">
            {propsArr.map(props => {

                return (
                    <SelectWheel key={props.name} {...props} partOfMultiple/>
                )
            })}
            <div className="select-overlay"></div>
        </div>
    )

}

const SelectWheel = ({ name, values, setFieldValue, setFieldTouched, options, unitLabel, partOfMultiple, className, height=300, itemHeight=30 }) => {
    const value = _.get(values,name);
    const selVal = _.find(options,opt => (opt.value === value)).text;
    const onChange = val => {
        const newVal = _.find(options,opt => (opt.text === val)).value;
        setFieldValue(name,newVal);
        setFieldTouched(name,newVal);
    }

    return (
        <SelectWheelClass 
            list={options.map(opt => opt.text)}
            selectedItem={selVal}
            setSelectedItem={onChange}
            itemHeight={itemHeight}
            height={height}
            unitLabel={unitLabel}
            partOfMultiple={partOfMultiple}
            className={className}
        />
    )
}

class SelectWheelClass extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef(null);
        this.skipScrollRef = React.createRef(false);
        this.centerTimeout = null;
        this.setupListRefs();
    }

    componentDidMount() {
        this.wrapperRef.current.scrollTop = this.selItemScrollTop();
    }

    render() {
        const { height, setSelectedItem, perspectiveOrigin='center center', unitLabel, partOfMultiple, className } = this.props;

        return (
            <div className={classnames("wheel-scroll-cont", { [className]: className})}>
                {!partOfMultiple && (<div className="select-overlay">
                    {unitLabel && (<div className="unit-label">{unitLabel}</div>)}
                </div>)}
                <Wrapper
                    onScroll={this.handleScroll}
                    perspectiveOrigin={perspectiveOrigin}
                    ref={this.wrapperRef}
                    height={`${height}px`}
                >
                    {this.paddedLoopingList().map((el, index) => {
                        const isNoop = el === 'noop';
                
                        return (
                            <ListItemClass
                                key={isNoop ? `noop${index}` : el}
                                select={isNoop ? (() => {}) : () => setSelectedItem(el)}
                                listRef={this.listRefs[el]}
                                isNoop={isNoop}
                                {...this.getItemProps(el)}
                            >
                                {el}
                            </ListItemClass>
                        );
                    })}
                </Wrapper>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        const { selectedItem } = this.props;
        const { selectedItem: prevSel } = prevProps;

        if(selectedItem !== prevSel) {
            if(!this.skipScrollRef.current) {
                const wrapper = this.wrapperRef.current;
                wrapper.scrollTo({ top: this.selItemScrollTop(), left: 0, behavior: 'smooth'});
            }
            this.skipScrollRef.current = false;
        }
    }

    componentWillUnmount() {
        clearTimeout(this.centerTimeout);
    }

    setupListRefs = () => {
        const { list } = this.props;

        this.listRefs = _.zipObject(list,list.map(item => React.createRef(null)));
    }

    selItemScrollTop = () => {
        const { selectedItem } = this.props;
        return this.getItemScrollTop(selectedItem);
    }

    selectedItemIndex = () => {
        const { list, selectedItem } = this.props;
        return list.findIndex((l) => l === selectedItem);
    }

    loopingList = () => {
        const { list } = this.props;
        return list;
    }

    paddedLoopingList = () => {
        const { list, itemHeight, height } = this.props;
        const paddingNeeded = Math.ceil((height/itemHeight) / 2);
        const noopArr = Array.from({ length: paddingNeeded }, (x, i) => 'noop');
        return [ ...noopArr, ...list, ...noopArr ];
    }

    getItemScrollTop = (item) => {
        const wrapper = this.wrapperRef.current;
        const maxScrollTop = this.getMaxScrollTop();
        const minScrollTop = 0;
        const curScrollTop = wrapper.scrollTop;

        const { distFromMiddle } = this.getItemProps(item);
        
        return Math.min(Math.max(curScrollTop + distFromMiddle,minScrollTop),maxScrollTop);
    }

    getItemProps = item => {
        const { itemHeight } = this.props;

        if(item === 'noop') {
            return { height: itemHeight };
        }

        const wrapper = this.wrapperRef.current;
        const el = this.listRefs[item].current;

        if(!wrapper || !el) {
            return { height: itemHeight };
        }

        const wrapRect = wrapper.getBoundingClientRect();
        const elRect = el.getBoundingClientRect();

        const topOffset = (elRect.y + elRect.height / 2) - wrapRect.y;
        const topOffsetPct = Math.min(Math.max((topOffset / wrapRect.height) * 100,0),100);

        const halfHeight = wrapRect.height / 2;
        const distFromMiddle = (topOffset - wrapRect.height/2);
        const pctDistFromMiddle = Math.min(Math.max((distFromMiddle / halfHeight) * 100,-100),100);

        return { topOffset, topOffsetPct, distFromMiddle, pctDistFromMiddle, height: itemHeight };
    }

    getMaxScrollTop = () => {
        const wrapper = this.wrapperRef.current;
        return wrapper.scrollHeight - wrapper.clientHeight;
    }

    getCenterItem = () => {
        const list = this.loopingList();

        return _.minBy(list,item => Math.abs(this.getItemProps(item).distFromMiddle));
    }

    handleScroll = (e) => {
        const { selectedItem, setSelectedItem } = this.props;
        const newCenter = this.getCenterItem();

        if(selectedItem !== newCenter) {
            setSelectedItem(newCenter);
            this.skipScrollRef.current = true;
        }

        clearTimeout(this.centerTimeout);
        this.centerTimeout = setTimeout(this.centerSelItem,100);
    }

    centerSelItem = () => {
        const newCenter = this.getCenterItem();
        const wrapper = this.wrapperRef.current;

        wrapper.scrollTo({ top: this.getItemScrollTop(newCenter), left: 0, behavior: 'smooth'});
        this.centerTimeout = null;
    }
}

const Wrapper = React.forwardRef(({ perspectiveOrigin, children, height, ...rest },ref) => {
    return (
        <div {...rest} className="wheel-scroller" style={{ height, minHeight: height }} ref={ref}>
            {children}
        </div>
    )
});

class ListItemClass extends React.Component {
     
    constructor(props) {
        super(props);
        this.itemRef = React.createRef(null);
    }

    render() {
        const { children, listRef, select } = this.props;

        return (
            <div style={this.calcStyle()} ref={listRef} onClick={select}>
                {children}
            </div>
        )
    }

    calcStyle = () => {
        const { height, isNoop, pctDistFromMiddle } = this.props;
        const fontWeight = 700;
        const color = `hsla(0deg, 0%, 15%, ${1 - (Math.abs(pctDistFromMiddle)/100)*0.85})`;

        return { height: `${height}px`, padding: '4px 8px', margin: '0', fontWeight, fontSize: '20px', color, opacity: (isNoop ? 0 : 1), cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' };
    }
}

export default SelectWheel;
