import * as actionTypes from 'redux/actionTypes'
import { combineReducers } from 'redux';
import * as _ from 'lib/utilities';
import { UserMeal } from 'lib/classes';
import moment from 'moment';
import { dateFormat } from 'config/settings';

const deviceReadyDefault = window.isCordova ? false : true;

const deepMergeState = (state,incoming,omitExisting=false,omitNonExisting=false) => {
    incoming = incoming || {};
    let newState = { ...(state || {}), ...incoming };
    if(state) {
        Object.entries(state).forEach(([id,obj]) => {
            newState[id] = { ...obj, ...(incoming[id] || {}) };
        })
    }
    if(omitExisting) {
        newState = _.pick(newState,Object.keys(incoming));
    }

    if(omitNonExisting) {
        newState = _.pick(newState,Object.keys(state));
    }
    
    return newState;
}

const omitForUser = (state,data,key) => {
    let userId = null;
    if(data.user) {
        userId = data.user.id;
    } else if(data.users) {
        userId = Number(Object.keys(data.users)[0]);
    }

    if(!_.isBlank(userId)) {
        return { ..._.omitBy(state,obj => obj.userId === userId), ...data[key] };
    }
    return { ...data[key] }
}

const omitChildrenOfType = (state,data,parentKey,childKey,fk,fkTypeKey=null,fkType=null) => {
    const parentIds = Object.keys(data[parentKey] || {}).map(id => Number(id));

    const filterFn = fkType ? (obj => (parentIds.includes(obj[fk]) && obj[fkTypeKey] === fkType)) : (obj => parentIds.includes(obj[fk]))

    if(parentIds.length > 0) {
        return { ..._.omitBy(state,filterFn), ...data[childKey] };
    }
    return { ...state, ...data[childKey] }
}

const timeDependentUserLoadHandler = (state,action,slice,uniqBy) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS: {
            const sliceData = action.data[slice] || {};
            if(!state && !action.data[slice]) {
                return state;
            } else if(uniqBy) {
                return _.uniqBy([ ...(sliceData || []), ...(state || []) ],uniqBy);
            } else {
                return deepMergeState(state,sliceData)
            }
        }
        default: 
            return state;
    }
}

const usersDiffer = (state,action) => {
    if(!action.data.user && state.user){
        return true;
    } else if(action.data.user && !state.user) {
        return true;
    } else if(action.data.user && state.user) {
        if(state.user.id !== action.data.user.id) {
            return true;
        } else {
            return false;
        }
    } else if(!action.data.user && !state.user) {
        return false;
    }
}

const paginatedResultsHandler = (state,data,resultsName,uniqBy,deepMergeKeys=[]) => {
    if(data.page === 1 || !state || !state[resultsName]) {
        return data;
    } else {
        let results = Array.isArray(data[resultsName]) ? [...state[resultsName], ...data[resultsName]] : { ...state[resultsName], ...data[resultsName] }
        if(uniqBy && Array.isArray(results)) {
            results = _.uniqBy(results,uniqBy);
        }
        const addlKeys = {};
        deepMergeKeys.forEach(key => {
            addlKeys[key] = { ...state[key], ...data[key] }
        })
        return { ...data, [resultsName]:  results, ...addlKeys }
    }
}

const tooManyLoadedDates = (state) => {
    if(state.data && state.data.loadedDates && state.data.loadedDates.length >= 15) {
        return true;
    } else {
        return false;
    }
}

const tooMuchDataLoaded = (state,action) => {

    if(!action.allowFullReload) {
        return false;
    }

    if(tooManyLoadedDates(state)) {
        return true;
    }

    const baseSize = _.roughSizeOfObj(action.data);
    const dataCutoff = Math.max(1000,baseSize*2);

    return _.roughSizeOfObj(state) > dataCutoff;
}

const userNeedsFullRefresh = (state,action) => {
    return (usersDiffer(state,action) || tooMuchDataLoaded(state,action));
}

const requiresFullReload = (state,action) => {
    if((action.type === actionTypes.LOAD_USER_SUCCESS && userNeedsFullRefresh(state,action)) ||
        actionTypes.FULL_RELOAD_ACTIONS.includes(action.type)) {
        return true;
    } else {
        return false;
    }
}

const foodDBSearchReducer = (state=null, action) => {
    switch (action.type) {
        case actionTypes.FOOD_DB_SEARCH_SUCCESS: {
            return paginatedResultsHandler(state,action.data.search,'results',null,['recipeCounts']);
        }
        case actionTypes.POPULATE_FATSECRET_SUCCESS: {
            return { ...state, results: state.results.map((foodOrId) => (action.data.food.fatsecretId === foodOrId.fatsecretId ? action.data.food.id : foodOrId))}
        }
        default:
            return state;
    }
}

const recipeSearchReducer = (state = null,action) => {
    switch (action.type) {
        case actionTypes.SETUP_REPLACE_RECIPE: {
            return { ...action.data }
        }
        case actionTypes.LOAD_RECIPE_SEARCH_RESULTS: {
            return paginatedResultsHandler(state,action.data.search,'results')
        }
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS: {
            return handleOverrideSearchResults(state,action);
        }
        default:
            return state;
    }
}

const uiReducer = (state = { 
    appRenderKey: `${Math.random()}`, 
    deviceReady: deviceReadyDefault, 
    renderKey: `${Math.random()}`, 
    viewportHeight: window.visualViewport.height, 
    showSaveFlash: false,
    unsavedState: null,
    clientCounts: {},
    uploadProgress: {},
    filterBtnFilts: { },
},  action) => {
    switch (action.type) {
        case actionTypes.REFRESH_CACHE: {
            return {...state, cacheRefreshed: action.showPopup, needsProPopup: (state.needsProPopup ? !action.showPopup : false), renderKey: `${Math.random()}` };
        }
        case actionTypes.DISMISS_REFRESH_CACHE: {
            return {...state, cacheRefreshed: false, needsProPopup: false, failedRequest: null, successRequest: null, clientMpQuotaHit: false, freeTrialExportQuotaHit: false, mscExportQuotaHit: false };
        }
        case actionTypes.SET_VIDEO_AUTOPLAY: {
            return { ...state, supportsAutoplay: action.data.value }
        }
        case actionTypes.DEVICE_READY: {
            return { ...state, deviceReady: true }
        }
        case actionTypes.PRO_REQUIRED: {
            return { ...state, needsProPopup: true, cacheRefreshed: false };
        }
        case actionTypes.SET_PAYWALL_REDIRECT: {
            return { ...state, paywallRedirect: action.data.path };
        }
        case actionTypes.REQUEST_FAILED: {
            return { ...state, cacheRefreshed: false, needsProPopup: false, successRequest: null, failedRequest: action.data.status }
        }
        case actionTypes.REQUEST_SUCCEEDED: {
            return { ...state, cacheRefreshed: false, needsProPopup: false, failedRequest: null, successRequest: action.data  }
        }
        case actionTypes.SET_VIEWPORT_HEIGHT: {
            return { ...state, viewportHeight: action.data.viewportHeight }
        }
        case actionTypes.SHOW_SAVE_FLASH: {
            return { ...state, showSaveFlash: (action.msg ? action.msg : true) }
        }
        case actionTypes.HIDE_SAVE_FLASH: {
            return { ...state, showSaveFlash: false }
        }
        case actionTypes.CLEAR_CACHE: {
            return { ...state, appRenderKey: `${Math.random()}`}
        }
        case actionTypes.ASSIGN_TRAINER:
        case actionTypes.LOAD_ALL_TRAINERS: {
            return { ...state, clientCounts: action.data.clientCounts }
        }
        case actionTypes.SET_UNSAVED_CHANGES: {
            return { ...state, unsavedState: action.data }
        }
        case actionTypes.CLEAR_UNSAVED_CHANGES:
        case actionTypes.SWITCH_CLIENT_SUCCESS:
        case actionTypes.ADMIN_SWITCH_USER_SUCCESS:
        case actionTypes.SWITCH_TO_TRAINER:
        case actionTypes.DISCARD_UNSAVED_CHANGES:
        case actionTypes.FULL_MEAL_PLAN_UPDATE: 
        case actionTypes.HANDLE_SHARE_MEAL_SIDE_EFFECTS: {
            return { ...state, unsavedState: null }
        }
        case actionTypes.START_MUX_UPLOAD_SUCCESS: {
            return { ...state, uploadProgress: { ...state.uploadProgress, [`${action.data.recordClass}|${action.data.recordId}`]: 1 } }
        }
        case actionTypes.MUX_VIDEO_UPLOAD_PROG: {
            return { ...state, uploadProgress: { ...state.uploadProgress, [`${action.data.recordClass}|${action.data.recordId}`]: action.data.percent } }
        }
        case actionTypes.CANCEL_MUX_UPLOAD_SUCCESS: {
            return { ...state, uploadProgress: _.omit(state.uploadProgress,[`${action.data.recordClass}|${action.data.recordId}`]) }
        }
        case actionTypes.SET_FILTER_BTN_FILTS: {
            return { ...state, filterBtnFilts: { ...state.filterBtnFilts, [action.data.filterKey]: { ...action.data.filters } } }
        }
        default:
            return state;

    }
}

const reconcileCleanupIgnoreDate = (state,action) => {
    let { ignoreRoutineCleanupDate, routineNeedsRegen: curRegenState } = (state || {});
    if(ignoreRoutineCleanupDate) {
        const { routineDirtyDate, routineNeedsRegen } = action.data.user;
        if(routineNeedsRegen && !curRegenState) {
            ignoreRoutineCleanupDate = null;
        } else if(routineDirtyDate) {
            const dirtyMoment = moment(routineDirtyDate);
            const ignoreMoment = moment(ignoreRoutineCleanupDate);
            if(dirtyMoment.isBefore(ignoreMoment)) {
                ignoreRoutineCleanupDate = null;
            }
        } 
    }
    return ignoreRoutineCleanupDate;
}

const userReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.ONBOARDING_REQUIRED: {
            if (state) {
                return { ...state, hasBasicProfile: false };
            } else {
                return state;
            }
        }
        case actionTypes.DISMISS_TOOLTIP: {
            if(state) {
                return { ...state, seenTooltips: _.union(state.seenTooltips || [],[action.data.tipName]) };
            } else {
                return state;
            }
        }
        case actionTypes.ACTIVATE_MESSAGING:
        case actionTypes.UPDATE_UNREAD_MSGS:
        case actionTypes.ADVANCE_WORKOUT_SETUP:
        case actionTypes.INIT_PRO_SUCCESS:
        case actionTypes.SET_NEXT_RATING_REQUEST: {
            return { ...state, ...action.data };
        }
        case actionTypes.UPDATE_MEAL_PLAN_SUCCESS:
        case actionTypes.INITIAL_RECIPE_OPTIONS:
        case actionTypes.LOG_WEIGHT_SUCCESS:
        case actionTypes.POSSIBLE_ROUTINES_SUCCESS:
        case actionTypes.CREATE_BODY_MEASUREMENTS:
        case actionTypes.UPDATE_SETTINGS:
        case actionTypes.FULL_MEAL_PLAN_UPDATE:
        case actionTypes.IGNORE_WARNINGS:
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.SET_RECIPE_MEAL_SERVINGS:
        case actionTypes.LOAD_MY_FOODS:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.CREATE_RECIPE:
        case actionTypes.UPLOAD_TRAINER_LOGO:
        case actionTypes.CREATE_CLIENT:
        case actionTypes.MARK_WEEK_CLEAN: {
            return { ...state, ...action.data.user };
        }
        case actionTypes.LOAD_CATEGORIZE_RECIPES:
        case actionTypes.LOAD_TEAM_RECIPES:
        case actionTypes.DNP_UPDATE_SUCCESS: {
            if(action.data.user) {
                return { ...state, ...action.data.user };
            }
            return state;
        }
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.user) {
                return { ...state, ...action.data.user };
            }
            return null;
        }
        case actionTypes.UPDATE_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_ROUTINE_CYCLE_SUCCESS:
        case actionTypes.UPDATE_CYCLE_DAY_ORDER_SUCCESS:
        case actionTypes.DESTROY_WRTD_SUCCESS:
        case actionTypes.DESTROY_WORKOUT_TEMPLATE:
        case actionTypes.UPDATE_WORKOUT_TEMPLATE_SUCCESS:
        case actionTypes.CREATE_EXERCISE_GROUP:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.UPDATE_WT_CHILD_ORDER:
        case actionTypes.UPDATE_SET_TEMPLATE: 
        case actionTypes.UPDATE_EXERCISE_TEMPLATE:
        case actionTypes.ADD_WT_CHILD_GROUP:
        case actionTypes.DESTROY_SET_TEMPLATE:
        case actionTypes.DESTROY_WTC:
        case actionTypes.SWAP_EXERCISE_TEMPLATE:
        case actionTypes.ADD_EXERCISE_TEMPLATE:
        case actionTypes.CREATE_EXERCISE:
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            if(action.data.user) {
                return { ...state, ...action.data.user, ignoreRoutineCleanupDate: null };
            }
            return state;
        }
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS: 
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_ROUTINE_SUCCESS: {
            return { ...state, ...action.data.user, ignoreRoutineCleanupDate: null };
        }
        case actionTypes.LOG_WORKOUT_SUCCESS:
        case actionTypes.EXERCISE_SETTING_UPDATE_SUCCESS:
        case actionTypes.DELOAD_SUCCESS: {
            return { ...state, ...action.data.user, ignoreRoutineCleanupDate: reconcileCleanupIgnoreDate(state,action) };
        }
        case actionTypes.MUTE_WORKOUT_TIMERS: {
            return { ...state, muteTimers: (action.data.muteTimers === 1) }
        }
        case actionTypes.PRO_REQUIRED: {
            if(state) {
                return { ...state, ...action.data };
            } else {
                return state;
            }
            
        }
        case actionTypes.DISLIKE_RECIPE_SUCCESS:
        case actionTypes.LIKE_RECIPE_SUCCESS: {
            return { ...state, ...action.data.user };
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS: {
            if(action.data.user.id === state.id) {
                const newState = { ...state, ...action.data.user, ignoreRoutineCleanupDate: reconcileCleanupIgnoreDate(state,action) };
                if(action.data.testedExercise) {
                    return { ...newState, testedExercises: _.uniq([ ...(newState.testedExercises || []), action.data.testedExercise ])}
                } else if(action.data.testedProg) {
                    return { ...newState, testedProgs: _.uniq([ ...(newState.testedProgs || []), action.data.testedProg ])}
                } else {
                    return newState;
                }
            }
            return state;
        }
        case actionTypes.SWAP_EXERCISE_SPEC: {
            return { ...state, ...action.data.user, ignoreRoutineCleanupDate: action.date };
        }
        case actionTypes.LOAD_ROUTINE_SUCCESS: {
            if(action.data.exerciseGroupsLoaded) {
                return { ...state, exerciseGroupsLoaded: true }
            }
            return state;
        }
        case actionTypes.UPDATE_TBASIC_PROFILE:
        case actionTypes.SWITCH_TTYPE_SUCCESS: {
            if(action.data.user && action.data.user.id === state.id) {
                return { ...state, ...action.data.user }
            }
            return state;
        }
        case actionTypes.UPDATE_MP_INFO_STUB: {
            if(action.data.mpNameScheme !== undefined) {
                return { ...state, mpNameScheme: action.data.mpNameScheme };
            }
            return state;
        }
        default:
            return state;

    }
};

const userMealsReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.RECENT_USER_MEALS_SUCCESS:
        case actionTypes.LOG_OFF_PLAN_SUCCESS:
        case actionTypes.LOG_FOOD_FROM_DB_SUCCESS: {
            return { ...(state || {}), ...action.data.userMeals };
        }
        case actionTypes.HEALTHKIT_DATA_RECEIVED: {
            if(action.data.userMeals) {
                return { ...(state || {}), ...action.data.userMeals };
            }
            return state;
        }
        case actionTypes.LOAD_CLIENT:
            const mealTypeIds = Object.values(action.data.mealTypes).map(mt => mt.id);
            return { ..._.omitBy(state || {},um => mealTypeIds.includes(um.mealTypeId)), ...action.data.userMeals };
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return action.data.userMeals;
        }
        case actionTypes.UNLOG_MEAL_SUCCESS:
        case actionTypes.LOG_MEAL_SUCCESS: {
            let newState = { ...(state || {}), ...action.data.userMeals };
            delete newState[action.data.destroyed];
            return newState;
        }
        case actionTypes.SKIP_MEAL_SUCCESS: {
            let type = UserMeal.SKIPPED;
            if(action.data.type !== 'skip') {
                type = UserMeal.UNLOGGED;
                
            } 
            return { ...state, [action.data.id]: { ...state[action.data.id], logType: type }};
        }
        case actionTypes.DESTROY_MINI_PROFILE: {
            return _.omit(state,action.data.userMealIds)
        }
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.HANDLE_SHARE_MEAL_SIDE_EFFECTS: {
            let newState = { ..._.omit(state, action.data.destroyedMeals.userMealIds), ...(action.data.userMeals || {}) };
            action.data.unlockIds.forEach(id =>  {
                if(newState[id]) {
                    newState[id] = { ...newState[id], pinned: false, lockedMealParentId: null }
                }
            })
            return newState;
        }
        default:
            return timeDependentUserLoadHandler(state,action,'userMeals');

    }
}

const recipeMealsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return action.data.recipeMeals;
        }
        case actionTypes.UNLOG_MEAL_SUCCESS:
        case actionTypes.LOG_MEAL_SUCCESS: {
            let newState = _.deleteWithFKs(state,'userMealId',_.compact([action.data.destroyed,action.data.userMealId]));
            newState = { ...newState, ...(action.data.recipeMeals || {}) };
            return newState;
        }
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_GLIST_SUCCESS:
        case actionTypes.SET_RECIPE_MEAL_SERVINGS: {
            return { ...state, ...action.data.recipeMeals }
        }
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.HANDLE_SHARE_MEAL_SIDE_EFFECTS: {
            return { ..._.omit(state, action.data.destroyedMeals.recipeMealIds), ...(action.data.recipeMeals || {}) };
        }
        case actionTypes.DESTROY_MINI_PROFILE: {
            return _.omit(state,action.data.recipeMealIds)
        }
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.CREATE_RECIPE_OVERRIDE: {
            if(_.isBlank(action.data.overrideId)) {
                return state;
            }
            return _.mapValues(state,recipeMeal => (recipeMeal.recipeId === action.data.overriddenId ? { ...recipeMeal, recipeId: action.data.overrideId } : { ...recipeMeal } ))
        }
        default: {
            return timeDependentUserLoadHandler(state,action,'recipeMeals');
        }
    }

}

const recipesReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_RECIPE_SEARCH_RESULTS:
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.GET_RANDOM_MEAL:
        case actionTypes.LOAD_RECENT_RECIPES:
        case actionTypes.LIKE_RECIPE_SUCCESS:
        case actionTypes.CREATE_RECIPE:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.LOAD_RECIPES_SUCCESS:
        case actionTypes.LOAD_GLIST_SUCCESS:
        case actionTypes.INITIAL_RECIPE_OPTIONS:
        case actionTypes.RECIPE_OPTIONS_CATEGORY_SUCCESS:
        case actionTypes.FULL_MEAL_PLAN_UPDATE: 
        case actionTypes.LOAD_CATEGORIZE_RECIPES:
        case actionTypes.DEACTIVATE_RECIPE_SUCCESS:
        case actionTypes.LOAD_TEAM_RECIPES:
        case actionTypes.LOAD_MEAL_SEARCH_RESULTS: {
            return deepMergeState(state,action.data.recipes)
        }
        case actionTypes.UPDATE_RECIPE:
            return { ...(state || {}), ...action.data.recipes }
        default:
            return timeDependentUserLoadHandler(state,action,'recipes');;
    }
}

const ingredientsReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.GET_RANDOM_MEAL:
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.LOAD_GLIST_SUCCESS:
        case actionTypes.CREATE_RECIPE:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS: {
            return { ...(state || {}), ...(action.data.ingredients || {}) };
        }
        case actionTypes.UPDATE_RECIPE: {
            const newState = _.omitBy(state,ingredient => ingredient.recipeId === action.data.id);
            return { ...newState, ...action.data.ingredients }
        }
        case actionTypes.LOAD_RECIPES_SUCCESS: {
            return omitChildrenOfType(state,action.data,'recipes','ingredients','recipeId');
        }
        default:
            return state;
    }
}

const foodWeightsReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.GET_FOOD_FROM_BARCODE_SUCCESS:
        case actionTypes.FOOD_DB_SEARCH_SUCCESS:
        case actionTypes.POPULATE_FATSECRET_SUCCESS:
        case actionTypes.GET_RANDOM_MEAL:
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.LOAD_GLIST_SUCCESS:
        case actionTypes.LOAD_MY_FOODS:
        case actionTypes.LOG_FOOD_FROM_DB_SUCCESS:
        case actionTypes.FOOD_LOAD:
        case actionTypes.UPDATE_FOOD:
        case actionTypes.CREATE_RECIPE:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.UPDATE_RECIPE:
        case actionTypes.LOAD_RECIPES_SUCCESS: {
            return { ...(state || {}), ...(action.data.foodWeights || {}) };
        }
        default:
            return state;
    }
}

const foodsReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.GET_FOOD_FROM_BARCODE_SUCCESS:
        case actionTypes.FOOD_DB_SEARCH_SUCCESS:
        case actionTypes.POPULATE_FATSECRET_SUCCESS:
        case actionTypes.GET_RANDOM_MEAL:
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.LOAD_GLIST_SUCCESS:
        case actionTypes.LOAD_MY_FOODS:
        case actionTypes.FOOD_LOAD:
        case actionTypes.UPDATE_FOOD:
        case actionTypes.DESTROY_FOOD: 
        case actionTypes.CREATE_RECIPE:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.UPDATE_RECIPE:
        case actionTypes.LOAD_RECIPES_SUCCESS: {
            return { ...(state || {}), ...action.data.foods };
        }
        case actionTypes.LOG_FOOD_FROM_DB_SUCCESS:{
            return { ...(state || {}), ...(action.data.foods || {}) };
        }
        default:
            return state;
    }
}

const mealTypesReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.LOAD_USER_SUCCESS:
        case actionTypes.LOAD_CLIENT:
        case actionTypes.UPDATE_MEAL_PLAN_SUCCESS:
        case actionTypes.UPDATE_CLIENT_MEAL_PLAN:
        case actionTypes.INITIAL_RECIPE_OPTIONS: {
            return omitForUser(state,action.data,'mealTypes');
        }
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            if(action.data.mealTypes) {
                if(action.data.user) {
                    const userId = action.data.user.id;
                    return { ..._.omitBy(state,mealType => mealType.userId === userId), ...action.data.mealTypes };
                }
                return { ...action.data.mealTypes }
            }
            return state;
        }
        default:
            return state;
    }
}

const singleDayMealsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return action.data.singleDayMeals;
        }
        default: {
            return state;
        }
    }
}

const handleNewWorkoutImageName = (state,action) => {
    if(action.data.newImageName && state) {
        const workout = state[action.data.newImageName.id];
        if(workout) {
            return { ...state, [workout.id]: { ...workout, imageName: action.data.newImageName.imageName }}
        }
    }
    return state;
}

const activityLogsReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS: 
            return { ...action.data.activityLogs };
        case actionTypes.CREATE_ACTIVITY_LOG:
        case actionTypes.UPDATE_ACTIVITY_LOG:
        case actionTypes.DESTROY_ACTIVITY_LOG:
        case actionTypes.LOAD_LOGS_SUCCESS: {
            return { ...(state || {}), ...action.data.activityLogs };
        }
        case actionTypes.HEALTHKIT_DATA_RECEIVED: {
            if(action.data.activityLogs) {
                return { ...(state || {}), ...action.data.activityLogs };
            }
            return state;
        }
        default:
            return timeDependentUserLoadHandler(state,action,'activityLogs');
    }
}

const workoutsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS: 
            return { ...action.data.workouts };
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS: {
            return { ...(state || {}), ...action.data.workouts };
        }
        case actionTypes.LOAD_CLIENT: {
            return { ..._.omitBy(state || {},workout => (workout.userId === action.data.clientId)), ...action.data.workouts }
        }
        case actionTypes.LOG_WORKOUT_SUCCESS: {
            const tmpState = state || {};
            return { ...tmpState, [action.id]: { ...(tmpState[action.id]), logged: action.data.logged }}
        }
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ..._.omit(state, action.clear.workoutIds ), ...action.data.workouts };
        }
        case actionTypes.SWAP_EXERCISE_SPEC: {
            return handleNewWorkoutImageName(state,action);
        }
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.workouts) {
                const newState = _.pick(state,Object.keys(action.data.workouts));
                return deepMergeState(newState,action.data.workouts);
            }
            return null;
        }
        default:
            return timeDependentUserLoadHandler(state,action,'workouts');
    }
}

const exerciseSpecificationsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS: 
            return { ...(action.data.exerciseSpecifications || {}) };
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.CREATE_ACTIVITY_LOG:
        case actionTypes.UPDATE_ACTIVITY_LOG:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.SWAP_EXERCISE_SPEC: {
            return { ...(state || {}), ...action.data.exerciseSpecifications }
        }
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ..._.omit(state, action.clear.exerciseSpecificationIds ), ...action.data.exerciseSpecifications };
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS:
            if(action.data.exerciseSpecifications) {
                return _.mergeExisting(state || {},action.data.exerciseSpecifications)
            }
            return state;
        default:
            return timeDependentUserLoadHandler(state,action,'exerciseSpecifications');
    }
}

const exerciseSetsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS: 
            return { ...(action.data.exerciseSets || {}) };
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.CREATE_ACTIVITY_LOG:
        case actionTypes.UPDATE_ACTIVITY_LOG:
        case actionTypes.LOAD_WORKOUT_SUCCESS: {
            return { ...(state || {}), ...action.data.exerciseSets }
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS:
            if(action.data.exerciseSets) {
                return { ...(state || {}), ...action.data.exerciseSets }
            }
            return state;
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ..._.omit(state, action.clear.exerciseSetIds ), ...action.data.exerciseSets };
        }
        default:
            return timeDependentUserLoadHandler(state,action,'exerciseSets');
    }
}

const workoutRoutinesReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.POSSIBLE_ROUTINES_SUCCESS:
        case actionTypes.UNPOSSIBLE_ROUTINES_SUCCESS:
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_ROUTINE_CYCLE_SUCCESS:
        case actionTypes.UPDATE_CYCLE_DAY_ORDER_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.DESTROY_WRTD_SUCCESS:
        case actionTypes.DESTROY_WORKOUT_TEMPLATE:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.UPDATE_WORKOUT_TEMPLATE_SUCCESS:
        case actionTypes.CREATE_EXERCISE_GROUP:
        case actionTypes.UPDATE_WT_CHILD_ORDER:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_SET_TEMPLATE:
        case actionTypes.UPDATE_EXERCISE_TEMPLATE:
        case actionTypes.ADD_WT_CHILD_GROUP:
        case actionTypes.DESTROY_SET_TEMPLATE:
        case actionTypes.DESTROY_WTC:
        case actionTypes.SWAP_EXERCISE_TEMPLATE:
        case actionTypes.ADD_EXERCISE_TEMPLATE:
        case actionTypes.CREATE_EXERCISE:
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS:
        case actionTypes.ASSIGN_CLIENT_ROUTINES:
        case actionTypes.SYNC_CLIENT_ROUTINES:
        case actionTypes.LOAD_CLIENT:
        case actionTypes.LOAD_TRAINER_ROUTINES:
        case actionTypes.IMPORT_ROUTINE:
            return { ...(state || {}), ...action.data.workoutRoutines};
        case actionTypes.LOAD_CLIENTS: {
            if(action.data.workoutRoutines) {
                if(action.data.meta && action.data.meta.page === 1) {
                    return deepMergeState(_.omit(state,action.removeRoutineIds),action.data.workoutRoutines);
                } else {
                    return deepMergeState(state,action.data.workoutRoutines);
                }
            }
            return state;
        }
        default:
            return timeDependentUserLoadHandler(state,action,'workoutRoutines');
    }
}

const possibleRoutinesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.POSSIBLE_ROUTINES_SUCCESS:
            return { match: action.data.possibleRoutines };
        case actionTypes.UNPOSSIBLE_ROUTINES_SUCCESS:
            return { ...(state || {}), other: action.data.possibleRoutines }
        default:
            return state;
    }
}

const recentUserMealsReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.RECENT_USER_MEALS_SUCCESS: {
            const { ids, ...rest } = action.data.recentUserMeals;
            const curIds = (state && state.ids) ? state.ids : [];
            return { ids: _.uniq([...curIds, ...ids]), ...rest };
        }
        case actionTypes.LOG_OFF_PLAN_SUCCESS:
        case actionTypes.LOG_FOOD_FROM_DB_SUCCESS: {
            let curIds = (state && state.ids) ? state.ids : [];
            if(action.replaceeId) {
                curIds = _.filter(curIds,(id) => (id !== action.replaceeId))
            }
            return { ...state, ids: [ _.values(action.data.userMeals)[0].id, ...curIds ] }
        }
        case actionTypes.UNLOG_MEAL_SUCCESS:
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return null;
        }
        default:
            return state;

    }
}

const exerciseProgressionsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_BANNABLE_PROGS_SUCCESS:
        case actionTypes.LOAD_PROGRESSION_REQUIREMENTS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS: 
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.SWAP_EXERCISE_SPEC:
        case actionTypes.EXECUTE_EXERCISE_SEARCH: {
            return { ...(state || {}), ...(action.data.exerciseProgressions || {}) }
        }
        default: 
            return state;
    }
}

const progressionExercisesReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_PROGRESSION_STANDALONE: 
        case actionTypes.LOAD_BANNABLE_PROGS_SUCCESS: {
            return { ...(state || {}), ...(action.data.progressionExercises || {}) }
        }
        default: {
            return state;
        }
    }
}

const bannableProgsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_BANNABLE_PROGS_SUCCESS:
        case actionTypes.LOAD_PROGRESSION_REQUIREMENTS:
            return Object.keys(action.data.exerciseProgressions);
        default: 
            return state;
    }
}

const nutritionParametersReducer = (state={}, action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS: 
        case actionTypes.LOAD_CLIENT:
        case actionTypes.UPDATE_MEAL_PLAN_SUCCESS:
        case actionTypes.DNP_UPDATE_SUCCESS:
        case actionTypes.DNP_LOAD_SUCCESS:
        case actionTypes.UPDATE_CLIENT_MEAL_PLAN:
        case actionTypes.INITIAL_RECIPE_OPTIONS: {
            return omitChildrenOfType(state,action.data,'dailyNutritionProfiles','nutritionParameters','dailyNutritionProfileId');
        }
        case actionTypes.UPDATE_SETTINGS: {
            if(action.data.dailyNutritionProfiles) {
                return omitChildrenOfType(state,action.data,'dailyNutritionProfiles','nutritionParameters','dailyNutritionProfileId');
            }
            return state;
        }
        case actionTypes.LOAD_CLIENTS: {
            if(action.data.nutritionParameters) {
                if(action.data.meta && action.data.meta.page === 1) {
                    return { ..._.omitBy(state,np => action.removeDnpIds.includes(np.dailyNutritionProfileId)), ...action.data.nutritionParameters };
                } else {
                    return { ...state, ...action.data.nutritionParameters };
                }
            }
            return state;
        }
        default:
            return state;
    }
}

const dailyNutritionProfilesReducer = (state={}, action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS: 
        case actionTypes.LOAD_CLIENT:
        case actionTypes.UPDATE_MEAL_PLAN_SUCCESS:
        case actionTypes.UPDATE_CLIENT_MEAL_PLAN:
        case actionTypes.INITIAL_RECIPE_OPTIONS: {
            return omitForUser(state,action.data,'dailyNutritionProfiles');
        }
        case actionTypes.UPDATE_SETTINGS: {
            if(action.data.dailyNutritionProfiles) {
                return omitForUser(state,action.data,'dailyNutritionProfiles');
            }
            return state;
        }
        case actionTypes.DNP_LOAD_SUCCESS:
        case actionTypes.DNP_UPDATE_SUCCESS: {
            return { ...state, ...action.data.dailyNutritionProfiles };
        }
        case actionTypes.LOAD_CLIENTS: {
            if(action.data.dailyNutritionProfiles) {
                if(action.data.meta && action.data.meta.page === 1) {
                    return { ..._.omitBy(state,dnp => action.removeUserIds.includes(dnp.userId)), ...action.data.dailyNutritionProfiles };
                } else {
                    return { ...state, ...action.data.dailyNutritionProfiles };
                }
            }
            return state;
        }
        default:
            return state;
    }
}

const weightRecordsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOG_WEIGHT_SUCCESS: {
            if(action.data.weightRecord) {
                return _.uniqBy([action.data.weightRecord, ...(state || [])], rec => rec.date)
            }
            return state;
        }
        case actionTypes.HEALTHKIT_DATA_RECEIVED: {
            if(action.data.weightRecords) {
                return _.uniqBy([...action.data.weightRecords, ...(state || [])], rec => rec.date)
            }
            return state;
        }
        default: {
            return timeDependentUserLoadHandler(state,action,'weightRecords',(itm) => (itm.id));
        }
    }
}

const allergyTagsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS:
            return [ ...action.data.allergyTags ];
        default:
            return state;
    }
}

const analyticsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.SET_ANALYTICS_DATA: {
            return { ...(state || {}), ...action.data }
        }
        default: {
            return state;
        }
    }
}

const lastLoadedWorkoutDayReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.LOAD_USER_SUCCESS: {
            return action.data.lastLoadedWorkoutDay || null;
        }
        default: {
            return state;
        }
    }
}

const wtcReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.CREATE_EXERCISE_GROUP:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_WT_CHILD_ORDER:
        case actionTypes.ADD_WT_CHILD_GROUP:
        case actionTypes.CREATE_EXERCISE:
        case actionTypes.ADD_EXERCISE_TEMPLATE: {
            return { ...(state || {}), ...(action.data.workoutTemplateChildren || {}) }
        }
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            return _.omit(state,Object.keys(action.data.workoutTemplateChildren || {}));
        }
        case actionTypes.DESTROY_WTC:
            return _.omit(state,action.data.workoutTemplateChildrenIds);
        case actionTypes.DESTROY_WRTD_SUCCESS:
        case actionTypes.DESTROY_WORKOUT_TEMPLATE:
            if(action.data.deleteTemplate) {
                return _.omit(state,action.data.workoutTemplateChildrenIds);
            }
            return state;
        default: {
            return state;
        }
    }
}

const workoutTemplatesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.UPDATE_WORKOUT_TEMPLATE_SUCCESS:
        case actionTypes.CREATE_EXERCISE_GROUP:
        case actionTypes.UPDATE_WT_CHILD_ORDER: {
            return { ...(state || {}), ...(action.data.workoutTemplates || {}) }
        }
        case actionTypes.DESTROY_WORKOUT_TEMPLATE:
        case actionTypes.DESTROY_WRTD_SUCCESS: {
            if(action.data.deleteTemplate) {
                return _.omit(state || {},action.data.workoutTemplateId);
            }
            return state;
        }
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            return _.omit(state,Object.keys(action.data.workoutTemplates || {}));
        }
        default: {
            return state;
        }
    }
}

const exerciseTemplatesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_EXERCISE_TEMPLATE:
        case actionTypes.SWAP_EXERCISE_TEMPLATE:
        case actionTypes.CREATE_EXERCISE:
        case actionTypes.ADD_EXERCISE_TEMPLATE: {
            return { ...(state || {}), ...(action.data.exerciseTemplates || {}) }
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS: 
        case actionTypes.SWAP_EXERCISE_SPEC: {
            if(action.data.exerciseTemplates) {
                return { ...(state || {}), ...action.data.exerciseTemplates }
            } else {
                return state;
            }
        }
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            return _.omit(state,Object.keys(action.data.exerciseTemplates || {}));
        }
        case actionTypes.DESTROY_WTC:
            if(action.data.exerciseTemplateIds) {
                return _.omit(state,action.data.exerciseTemplateIds);
            }
            return state;
        case actionTypes.DESTROY_WORKOUT_TEMPLATE:
        case actionTypes.DESTROY_WRTD_SUCCESS:
            if(action.data.deleteTemplate) {
                return _.omit(state,action.data.exerciseTemplateIds);
            }
            return state;
        default: {
            return state;
        }
    }
}

const setTemplatesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.UPDATE_SET_TEMPLATE:
        case actionTypes.CREATE_EXERCISE:
        case actionTypes.ADD_EXERCISE_TEMPLATE: {
            return { ...(state || {}), ...(action.data.setTemplates || {}) }
        }
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            return _.omit(state,Object.keys(action.data.setTemplates || {}));
        }
        case actionTypes.DESTROY_SET_TEMPLATE:
            return _.omit(state,[action.data.destroyed]);
        case actionTypes.DESTROY_WORKOUT_TEMPLATE:
        case actionTypes.DESTROY_WRTD_SUCCESS:
            if(action.data.deleteTemplate) {
                return _.omit(state,action.data.setTemplateIds);
            }
            return state;
        case actionTypes.UPDATE_EXERCISE_TEMPLATE: {
            const newState = _.omitBy(state,setTemplate => (setTemplate.exerciseTemplateId === action.data.exerciseTemplateId));
            return { ...newState, ...action.data.setTemplates }
        }
        default: {
            return state;
        }
    }
}

const exercisesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES:
        case actionTypes.IMPORT_EXERCISE_GROUPS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.CLEANUP_ROUTINE_SUCCESS: 
        case actionTypes.EXECUTE_EXERCISE_SEARCH:
        case actionTypes.LOAD_PROGRESSION_STANDALONE:
        case actionTypes.LOAD_STRENGTH_TEST_SUCCESS:
        case actionTypes.UPDATE_EXERCISE:
        case actionTypes.CREATE_EXERCISE:
        case actionTypes.CREATE_CUSTOM_EXERCISE:
        case actionTypes.SWAP_EXERCISE_SPEC:
        case actionTypes.WORKOUT_ACTION_SUCCESS:  {
            return { ...(state || {}), ...(action.data.exercises || {}) }
        }
        case actionTypes.LOAD_BANNABLE_PROGS_SUCCESS:
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.LOAD_FORMS_SUCCESS:
        case actionTypes.UPDATE_FORM_FIELD_SUCCESS:
        case actionTypes.RESTART_MUX_UPLOAD:
        case actionTypes.CANCEL_MUX_UPLOAD_SUCCESS:
        case actionTypes.START_MUX_UPLOAD_SUCCESS: {
            if(action.data.exercises) {
                return { ...(state || {}), ...(action.data.exercises || {}) }
            }
            return state;
        }
        default: {
            return timeDependentUserLoadHandler(state,action,'exercises')
        }
    }
}

const exerciseSettingsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.EXERCISE_SETTING_UPDATE_SUCCESS: {
            return { ...(state || {}), ...action.data.exerciseSettings }
        }
        default: {
            return timeDependentUserLoadHandler(state,action,'exerciseSettings')
        }
    }
}

const exerciseSearchReducer = (state={},action) => {
    switch (action.type) {
        case actionTypes.EXECUTE_EXERCISE_SEARCH: {
            const { exercises, exerciseProgressions, ...resultsData } = action.data;
            return { ...state, ...paginatedResultsHandler(state,resultsData,'results',result => `${result.id}-${result.type}`) };
        }
        case actionTypes.RESET_EXERCISE_SEARCH: {
            return { ...action.data };
        }
        case actionTypes.CACHE_EXERCISE_SEARCH: {
            return { ...state, formValues: action.data }
        }
        case actionTypes.UPDATE_EXERCISE_TEMPLATE: {
            if(state.context && state.context === 'ExerciseTemplate') {
                return { ...state, context: null }
            }
            return state;
        }
        case actionTypes.INSERT_NEW_EXERCISE_TO_TOP: {
            if(state && state.results) {
                return { ...state, results: [{id: action.data.exerciseId, type: 'Exercise' }, ...state.results] }
            }
            return state;
        }
        default:
            return state;
    }
}

const warmupsReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.CLEANUP_ROUTINE_SUCCESS:
        case actionTypes.RESTART_WORKOUT_SUCCESS:
        case actionTypes.LOAD_WORKOUT_SUCCESS:
        case actionTypes.LOAD_ROUTINE_SUCCESS: {
            if(action.data.warmups) {
                return { ...action.data.warmups }
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
}

const routineCyclesReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_ROUTINE_SUCCESS:
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.UPDATE_ROUTINE_CYCLE_SUCCESS: {
            return { ...(state || {}), ...action.data.routineCycles };
        }
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            return _.omit(state,Object.keys(action.data.routineCycles || {}));
        }
        default: {
            return state;
        }
    }
}

const workoutRoutineDaysReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_ROUTINE_SUCCESS: 
        case actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS:
        case actionTypes.UPDATE_CYCLE_DAY_ORDER_SUCCESS:
        case actionTypes.CREATE_WRTD_SUCCESS:
        case actionTypes.IMPORT_WORKOUT_TEMPLATES: {
            return { ...(state || {}), ...action.data.workoutRoutineDays };
        }
        case actionTypes.DESTROY_WRTD_SUCCESS: {
            return _.omit(state || {}, action.data.id);
        }
        case actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS: {
            return _.omit(state,Object.keys(action.data.workoutRoutineDays || {}));
        }
        default: {
            return state;
        }
    }
}

const progressionSchemesReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_ROUTINE_SUCCESS: {
            return { ...action.data.progressionSchemes }
        }
        default:
            return state;
    }
}


const exerciseProgressionRequirementsReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.LOAD_PROGRESSION_REQUIREMENTS: {
            return { ...(state || {}), ...action.data.exerciseProgressionRequirements }
        }
        case actionTypes.UPDATE_EXERCISE_TEMPLATE: {
            if(!action.data.exerciseProgressionRequirements) {
                return state;
            }
            const newState = _.omitBy((state || {}),epr => epr.exerciseTemplateId === action.data.exerciseTemplateId);
            return { ...newState, ...action.data.exerciseProgressionRequirements }
        }
        default:
            return state;
    }
}

const notificationsReducer = (state=[],action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS:
            return action.data.notifications;
        case actionTypes.NOTIFICATIONS_READ:
            let newState = [];
            const checked = action.data.notifications.map(n => n.id);
            state.forEach(n => {
                const newN = checked.includes(n.id) ? { ...n, checked: true } : { ...n };
                newState.push(newN);
            })
            return newState;
        default:
            return state;
    }
}

const miniProfilesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.INIT_MINI_PROFILES:
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return { ...action.data.miniProfiles }
        }
        case actionTypes.UPDATE_MINI_PROFILE: {
            return { ...state, ...action.data.miniProfiles }
        }
        case actionTypes.DESTROY_MINI_PROFILE: {
            return _.omit(state,[action.data.miniProfileId])
        }
        default:
            return state;
    }
}

const handleOverrideMeal = (overrideId,overriddenId,meal) => {
    const newMeal = { ...meal, sideDishes: meal.sideDishes.map(dish => (dish.recipeId === overriddenId ? { ...dish, recipeId: overrideId } : dish))}
    return newMeal;
}

const handleOverrideSearchResults = (state,action) => {
    const overrideId = action.data.overrideId;
    const overriddenId = action.data.overriddenId;
    if(_.isBlank(overrideId) || !state) {
        return state;
    }
    let results = state.results.map(result => handleOverrideMeal(overrideId,overriddenId,result))
    return { ...state, results };
}

const mealSearchReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.SETUP_MEAL_SWAP: {
            return { ...action.data }
        }
        case actionTypes.LOAD_MEAL_SEARCH_RESULTS: {
            return paginatedResultsHandler(state,action.data.search,'results')
        }
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS: {
            return handleOverrideSearchResults(state,action);
        }
        default:
            return state;
    }
}

const mealSearchCategoriesReducer = (state=null, action) => {
    switch(action.type) {
        case actionTypes.INITIAL_RECIPE_OPTIONS:
        case actionTypes.LOAD_MEAL_SEARCH_CATEGORIES: {
            return { ...action.data.mealSearchCategories, timestamp: moment().format(dateFormat) }
        }
        default:
            return state;
    }
}

const recentRecipesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_RECENT_RECIPES: {
            return { ...paginatedResultsHandler(state,action.data.recents,'recipeIds'), timestamp: (action.data.recents.page <= 1 ? moment().format(dateFormat) : state.timestamp) }
        }
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS: {
            const { overrideId, overriddenId } = action.data;
            if(_.isBlank(overrideId) || !state) {
                return state;
            }
            return { ...state, recipeIds: state.recipeIds.map(recipeId => (recipeId === overriddenId ? overrideId : recipeId ))};
        }
        default:
            return state;
    }
}

const tempMealReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.GET_RANDOM_MEAL:
        case actionTypes.REPLACE_TEMP_RECIPE:
        case actionTypes.START_EDIT_TEMP_MEAL: {
            return { ...action.data.meal };
        }
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.CREATE_RECIPE_OVERRIDE: {
            const { overrideId, overriddenId } = action.data;
            if(_.isBlank(overrideId) || !state) {
                return state;
            }
            return handleOverrideMeal(overrideId,overriddenId,state);
        }
        default:
            return state;
    }
}

const currentScannedFoodReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.GET_FOOD_FROM_BARCODE_SUCCESS: {
            if(action.data.foodId) {
                return { foodId: action.data.foodId };
            } else {
                return null;
            }
        }
        case actionTypes.CLEAR_SCANNED_FOOD: {
            return null;
        }
        default: {
            return state;
        }
    }
}

const workoutImageCategoriesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_ROUTINE_SUCCESS: 
        case actionTypes.LOAD_WIMAGE_CATS: {
            return action.data.workoutImageCategories ? { ...action.data.workoutImageCategories } : state;
        }
        case actionTypes.DESTROY_WIMAGE_CAT: {
            return _.omit(state,action.data.id)
        }
        case actionTypes.UPDATE_WIMAGE_CAT: {
            return { ...state, ...action.data.workoutImageCategories }
        }
        default:
            return state;
    }
}

const findMatchingGlistItem = (groups,item) => _.find(_.flatMap(groups,group => group.groceryItems),innerItem => item.foodId === innerItem.foodId)
const clearGlistItemFrom = (groups,item) => _.filter(groups.map(group => ({ ...group, groceryItems: _.filter(group.groceryItems,innerItem => innerItem.foodId !== item.foodId)})),group => group.groceryItems.length > 0);
const addGlistItemTo = (groups,item) => {
    const existingGroupInd = _.findIndex(groups,group => group.name === item.groupName);
    if(existingGroupInd !== -1) {
        const newGroups = [ ...groups ];
        const existingGroup = groups[existingGroupInd];
        const newGroup = { ...existingGroup };
        newGroup.groceryItems = [ ...existingGroup.groceryItems, item ]
        newGroups.splice(existingGroupInd,1,newGroup);
        return newGroups;
    }
    return [ ...groups, { name: item.groupName, groceryItems: [item] }]
}
const switchGroceryItem = (from,to,item) => {
    let targ = findMatchingGlistItem(to,item) || { recipeMealIds: [], amount: 0, foodWeightId: item.foodWeightId, foodId: item.foodId };
    const targRecMeals = _.union(targ.recipeMealIds,item.recipeMealIds);
    const targAmount = item.mergedAmount(targ);
    const newFrom = clearGlistItemFrom(from,item);
    const newItem = {...item.rawValues(), amount: targAmount, recipeMealIds: targRecMeals };
    let newTo = clearGlistItemFrom(to,item);
    return { from: newFrom, to: addGlistItemTo(newTo,newItem) }
}

const doGlistAction = (state,items,fromKey,toKey) => {
    let newState = { ...state };
    items.forEach(item => {
        const { from, to } = switchGroceryItem(newState[fromKey],newState[toKey],item);
        newState = { [fromKey]: from, [toKey]: to }
    })
    return newState;
}

const groceryListReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_GLIST_SUCCESS: {
            return { ...action.data.groceryList }
        }
        case actionTypes.CREATE_PANTRY_ITEM: {
            return doGlistAction(state,action.items,'needed','bought');
        }
        case actionTypes.DESTROY_PANTRY_ITEM: {
            return doGlistAction(state,action.items,'bought','needed');
        }
        case actionTypes.FULL_MEAL_PLAN_UPDATE:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
        case actionTypes.RESTORE_RECIPE_DEFAULTS:
        case actionTypes.UPDATE_RECIPE:
        case actionTypes.HANDLE_MEAL_UPDATE:
        case actionTypes.HANDLE_SHARE_MEAL_SIDE_EFFECTS:
        case actionTypes.LOG_MEAL_SUCCESS:
        case actionTypes.UNLOG_MEAL_SUCCESS:
        case actionTypes.DESTROY_MINI_PROFILE: {
            return null;
        }
        default:
            return state;
    }
}

const usersReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.users) {
                let result = deepMergeState(state,action.data.users);
                return result;
            }
            return null;
        }
        case actionTypes.UPDATE_TBASIC_PROFILE:
        case actionTypes.TSAC_SIGNUP_SUCCESS:
        case actionTypes.SWITCH_TTYPE_SUCCESS:
        case actionTypes.UPLOAD_TRAINER_LOGO:
        case actionTypes.CREATE_CHILD_TRAINER:
        case actionTypes.ASSIGN_CLIENT_ROUTINES:
        case actionTypes.UPDATE_CLIENTS:
        case actionTypes.CREATE_CLIENT:
        case actionTypes.UPDATE_CLIENT_MEAL_PLAN:
        case actionTypes.ASSIGN_TRAINER:
        case actionTypes.LOAD_TEAM_RECIPES:
        case actionTypes.SEND_INVITE:
        case actionTypes.UPDATE_SETTINGS:
        case actionTypes.LOAD_ALL_TRAINERS:
        case actionTypes.DEREACTIVATE_TRAINERS:
        case actionTypes.UPDATE_TRAINER:
        case actionTypes.LOAD_SCHEDULABLE_SETTINGS:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.LOAD_CHATS:
        case actionTypes.LOAD_FORMS_SUCCESS:
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CLIENT: {
            if(action.data.users) {
                return deepMergeState(state,action.data.users);
            }
            return state;
        }
        case actionTypes.LOAD_CLIENTS: {
            if(action.data.users) {
                if(action.data.meta && action.data.meta.page === 1) {
                    return deepMergeState(_.omit(state,action.removeUserIds),action.data.users);
                } else {
                    return deepMergeState(state,action.data.users);
                }
            }
            return state;
        }
        case actionTypes.LOAD_TRAINER_ROUTINES: {
            if(action.data.users) {
                return { ...state, ...action.data.users }
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const subscriptionsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.TSAC_SIGNUP_SUCCESS:
        case actionTypes.TRAINER_SUB_UPDATED:
        case actionTypes.LOAD_USER_SUCCESS:
        case actionTypes.LOAD_SUBSCRIPTION: {
            if(action.data.subscriptions) {
                return { ...state, ...action.data.subscriptions };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const clientTagsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.trainerData) {
                return { ...(state || {}), ...action.data.clientTags }
            }
            return null;
        }
        case actionTypes.TAG_CLIENTS: {
            return { ...(state || {}), ...action.data.clientTags }
        }
        default: {
            return state;
        }
    }
}

const recipePreferencesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LIKE_RECIPE_SUCCESS:
        case actionTypes.DISLIKE_RECIPE_SUCCESS:
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.recipePreferences) {
                return { ...action.data.recipePreferences }
            }
            return null;
        }
        default: {
            return state;
        }
    }
}

const trainerNotesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_CLIENT: {
            return { ...action.data.trainerNotes }
        }
        case actionTypes.CRUPDATE_TRAINER_NOTE: {
            return { ...state, ...action.data.trainerNotes }
        }
        case actionTypes.DESTROY_TRAINER_NOTE: {
            return _.omit(state,[action.data.noteId]);
        }
        default: {
            return state;
        }
    }
}

const habitsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_CLIENT:
        case actionTypes.LOAD_USER_SUCCESS: {
            return { ...state, ...action.data.habits }
        }
        case actionTypes.UPDATE_HABIT: {
            return { ...state, ...action.data.habits }
        }
        case actionTypes.LOG_WORKOUT_SUCCESS:
        case actionTypes.CREATE_ACTIVITY_LOG:
        case actionTypes.UPDATE_ACTIVITY_LOG:
        case actionTypes.DESTROY_ACTIVITY_LOG:
        case actionTypes.LOAD_SCHEDULABLE_SETTINGS:
        case actionTypes.HEALTHKIT_DATA_RECEIVED: {
            if(action.data.habits) {
                return { ...state, ...action.data.habits };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const schedulableSettingsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_USER_SUCCESS:
        case actionTypes.LOAD_CLIENT: {
            return omitForUser(state,action.data,'schedulableSettings');
        }
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return omitChildrenOfType(state,action.data,'forms','schedulableSettings','sourceId','sourceType','Form');
        }
        case actionTypes.REMOVE_FORM_AS_DEF: {
            return _.omit(state,[action.data.schedulableSettingId]);
        }
        case actionTypes.SET_FORM_DEFAULT_SCHED: {
            return { ...state, ...action.data.schedulableSettings };
        }
        default: {
            return state;
        }
    }
}

const habitLogsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_CLIENT:
        case actionTypes.LOAD_USER_SUCCESS: {
            return omitForUser(state,action.data,'habitLogs');
        }
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOG_HABIT_SUCCESS: {
            return { ...state, ...action.data.habitLogs };
        }
        case actionTypes.LOG_WORKOUT_SUCCESS:
        case actionTypes.CREATE_ACTIVITY_LOG:
        case actionTypes.UPDATE_ACTIVITY_LOG:
        case actionTypes.DESTROY_ACTIVITY_LOG:
        case actionTypes.HEALTHKIT_DATA_RECEIVED: {
            if(action.data.habitLogs) {
                return { ...state, ...action.data.habitLogs };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const pdfExportSettingsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.pdfExportSettings) {
                return { ...action.data.pdfExportSettings }
            }
            return null;
        }
        case actionTypes.UPDATE_PDF_EXPORT_SETTINGS: {
            return { 0: { ...action.data.values } }
        }
        default: {
            return state;
        }
    }
}

const mpInfoStubsReducer = (state=null,action) => {
    if(actionTypes.FULL_RELOAD_ACTIONS.includes(action.type)) {
        if(action.data.mpInfoStubs) {
            return { ...action.data.mpInfoStubs }
        }
        return state;
    }

    switch(action.type) {
        case actionTypes.LOAD_CLIENT:
        case actionTypes.LOAD_USER_SUCCESS:
        case actionTypes.UPDATE_MEAL_PLAN_SUCCESS:
        case actionTypes.PUBLISH_MEAL_PLAN:
        case actionTypes.UPDATE_CLIENT_MEAL_PLAN:
        case actionTypes.FULL_MEAL_PLAN_UPDATE:
        case actionTypes.UPDATE_MP_INFO_STUB: {
            if(action.data.mpInfoStubs) {
                return { ...state, ...action.data.mpInfoStubs }
            }
            return state;
        }
        default: {
            return state;
        }
    }
}


const jwtReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_USER_SUCCESS:
        case actionTypes.GET_JWT:
            return action.data.jwt || null;
        default: {
            return state;
        }
    }
}

const chatsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_CHATS:
            return deepMergeState(state,action.data.chats,action.data.initial);
        case actionTypes.CHAT_DATA_RECEIVED:
            if(action.data.chats) {
                return deepMergeState(state,action.data.chats);
            }
            return state;
        case actionTypes.LOAD_CHAT_MESSAGES:
            const specialKeys  = ['topDone','bottomDone','topCutoff','bottomCutoff'];
            const newState = state || {};
            let chat = { ...newState[action.data.chatId], ...action.data.chats[action.data.chatId] };
            if(action.data.initial) {
                chat = _.omit(chat,specialKeys);
            }
            if(chat) {
                chat = { ...chat, ..._.pick(action.data,specialKeys) };
                return { ...state, [chat.id]: chat };
            }

            return state;
        default: {
            return state;
        }
    }
}

const chatMembershipsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_CHATS:
            if(action.data.initial) {
                return { ...action.data.chatMemberships }
            }
            return { ...state, ...action.data.chatMemberships }
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.CHAT_DATA_RECEIVED:
            if(action.data.chatMemberships) {
                return { ...state, ...action.data.chatMemberships }
            }
            return state;
        default: {
            return state;
        }
    }
}

const chatMessagesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_CHATS:
        case actionTypes.CHAT_DATA_RECEIVED:
            if(action.data.chatMessages) {
                return { ...state, ...action.data.chatMessages }
            }
            return state;
        case actionTypes.LOAD_CHAT_MESSAGES:
            let newState;
            if(action.data.initial) {
                newState = _.omitBy(state,(msg) => msg.chatId === action.data.chatId);
            } else {
                newState = state;
            }
            return { ...newState, ...action.data.chatMessages }
        default: {
            return state;
        }
    }
}

const chatEventssReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.CHAT_DATA_RECEIVED:
            if(action.data.chatEvents) {
                return { ...state, ...action.data.chatEvents }
            }
            return state;
        case actionTypes.LOAD_CHAT_MESSAGES:
            let newState;
            if(action.data.initial) {
                newState = _.omitBy(state,(evt) => evt.chatId === action.data.chatId);
            } else {
                newState = state;
            }
            return { ...newState, ...action.data.chatEvents }
        default: {
            return state;
        }
    }
}

const formsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.UPDATE_FORM_SUCCESS:
        case actionTypes.SET_FORM_DEFAULT_SCHED:
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return { ...state, ...action.data.forms };
        }
        case actionTypes.LOAD_USER_SUCCESS:
        case actionTypes.LOAD_CLIENT: {
            return omitForUser(state,action.data,'forms');
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.LOAD_LOGS_SUCCESS:
        case actionTypes.LOAD_SCHEDULABLE_SETTINGS: {
            if(action.data.forms) {
                return { ...state, ...action.data.forms };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const formFieldsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.UPDATE_FORM_FIELD_SUCCESS:
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return { ...state, ...action.data.formFields };
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.UPDATE_FORM_SUCCESS: {
            if(action.data.formFields) {
                return { ...state, ...action.data.formFields };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const userDataEntriesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return { ...state, ...action.data.userDataEntries };
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.UPDATE_FORM_FIELD_SUCCESS: {
            if(action.data.userDataEntries) {
                return { ...state, ...action.data.userDataEntries };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const assessmentsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.UPDATE_ASSESSMENT_SUCCESS:
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return { ...state, ...action.data.assessments };
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.UPDATE_FORM_FIELD_SUCCESS:
        case actionTypes.RESTART_MUX_UPLOAD:
        case actionTypes.CANCEL_MUX_UPLOAD_SUCCESS:
        case actionTypes.START_MUX_UPLOAD_SUCCESS: {
            if(action.data.assessments) {
                return { ...state, ...action.data.assessments };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const assessmentResultsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return { ...state, ...action.data.assessmentResults };
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.LOAD_MUX_RECORD:
        case actionTypes.RESTART_MUX_UPLOAD:
        case actionTypes.CANCEL_MUX_UPLOAD_SUCCESS:
        case actionTypes.START_MUX_UPLOAD_SUCCESS: 
        case actionTypes.UPDATE_FORM_FIELD_SUCCESS: {
            if(action.data.assessmentResults) {
                return { ...state, ...action.data.assessmentResults };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const progressPhotosReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_PROGRESS_PHOTOS: {
            return { ...action.data.progressPhotos };
        }
        case actionTypes.CREATE_PROGRESS_PHOTO:
        case actionTypes.LOAD_FORMS_SUCCESS: {
            return { ...state, ...action.data.progressPhotos };
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.UPDATE_FORM_FIELD_SUCCESS: {
            if(action.data.progressPhotos) {
                return { ...state, ...action.data.progressPhotos };
            }
            return state;
        }
        case actionTypes.DESTROY_PROGRESS_PHOTO: {
            return _.omit(state,[action.data.destroyedId]);
        }
        default: {
            return state;
        }
    }
}

//COMMENT FOR GENERATOR, DO NOT TOUCH. ADD NEW DATA REDUCER ABOVE HERE

const dataReducerCore = combineReducers({
    user: userReducer,
    mealTypes: mealTypesReducer,
    userMeals: userMealsReducer,
    singleDayMeals: singleDayMealsReducer,
    activityLogs: activityLogsReducer,
    workouts: workoutsReducer,
    workoutRoutines: workoutRoutinesReducer,
    recipeMeals: recipeMealsReducer,
    recipes: recipesReducer,
    ingredients: ingredientsReducer,
    foodWeights: foodWeightsReducer,
    foods: foodsReducer,
    weightRecords: weightRecordsReducer,
    loadedDates: (state=null, action) => (timeDependentUserLoadHandler(state,action,'loadedDates',(date) => date)),
    loadedMpDates: (state=null, action) => ((action.data && action.data.loadedMpDates) ? action.data.loadedMpDates : state),
    lastLoadedWorkoutDay: lastLoadedWorkoutDayReducer, 
    nutritionParameters: nutritionParametersReducer,
    dailyNutritionProfiles: dailyNutritionProfilesReducer,
    recentUserMeals: recentUserMealsReducer,
    exerciseProgressions: exerciseProgressionsReducer,
    progressionExercises: progressionExercisesReducer,
    bannableProgressions: bannableProgsReducer,
    possibleRoutines: possibleRoutinesReducer,
    allergyTags: allergyTagsReducer,
    workoutTemplateChildren: wtcReducer,
    workoutTemplates: workoutTemplatesReducer,
    exerciseTemplates: exerciseTemplatesReducer,
    setTemplates: setTemplatesReducer,
    exercises: exercisesReducer,
    exerciseSpecifications: exerciseSpecificationsReducer,
    exerciseSets: exerciseSetsReducer,
    exerciseSettings: exerciseSettingsReducer,
    warmups: warmupsReducer,
    exerciseSearch: exerciseSearchReducer,
    routineCycles: routineCyclesReducer,
    workoutRoutineDays: workoutRoutineDaysReducer,
    progressionSchemes: progressionSchemesReducer,
    exerciseProgressionRequirements: exerciseProgressionRequirementsReducer,
    notifications: notificationsReducer,
    miniProfiles: miniProfilesReducer,
    mealSearch: mealSearchReducer,
    recipeSearch: recipeSearchReducer,
    mealSearchCategories: mealSearchCategoriesReducer,
    recentRecipes: recentRecipesReducer,
    tempMeal: tempMealReducer,
    foodDBSearch: foodDBSearchReducer,
    currentScannedFood: currentScannedFoodReducer,
    workoutImageCategories: workoutImageCategoriesReducer,
    groceryList: groceryListReducer,
    users: usersReducer,
    clientTags: clientTagsReducer,
    recipePreferences: recipePreferencesReducer,
    subscriptions: subscriptionsReducer,
    trainerNotes: trainerNotesReducer,
    habits: habitsReducer,
    schedulableSettings: schedulableSettingsReducer,
    habitLogs: habitLogsReducer,
    pdfExportSettings: pdfExportSettingsReducer,
    mpInfoStubs: mpInfoStubsReducer,
    jwt: jwtReducer,
    chats: chatsReducer,
    chatMemberships: chatMembershipsReducer,
    chatMessages: chatMessagesReducer,
    chatEvents: chatEventssReducer,
    forms: formsReducer,
    formFields: formFieldsReducer,
    userDataEntries: userDataEntriesReducer,
    assessments: assessmentsReducer,
    assessmentResults: assessmentResultsReducer,
    progressPhotos: progressPhotosReducer
    //COMMENT FOR GENERATOR, DO NOT TOUCH. ADD NEW DATA REDUCER MAPPING ABOVE HERE
});

const filterFullReloadData = data => {
    const { trainerData, trainerRefreshAt, otherClientInfo, trainerCache, branding, ...rest } = data;
    return { ...rest };
}

const dataReducer = (state,action) => {
    if(requiresFullReload(state,action)) {
        return filterFullReloadData(action.data);
    } else if(actionTypes.CLEAR_USER_ACTIONS.includes(action.type) || action.type === actionTypes.CLEAR_CACHE) {
        return { 
            user: null, 
            mealTypes: null, 
            userMeals: null, 
            singleDayMeals: null, 
            activityLogs: null,
            workouts: null, 
            workoutRoutines: null, 
            recipeMeals: null, 
            recipes: null,
            ingredients: null,
            foodWeights: null,
            foods: null,
            weightRecords: null,
            loadedDates: null,
            lastLoadedWorkoutDay: null,
            nutritionParameters: null,
            dailyNutritionProfiles: null,
            recentUserMeals: null,
            recipeSearch: null,
            exerciseProgressions: null,
            progressionExercises: null,
            bannableProgressions: null,
            possibleRoutines: null,
            allergyTags: null,
            workoutTemplateChildren: null,
            workoutTemplates: null,
            exerciseTemplates: null,
            setTemplates: null,
            exercises: null,
            exerciseSpecifications: null,
            exerciseSets: null,
            exerciseSettings: null,
            notifications: null,
            miniProfiles: null,
            mealSearch: null,
            mealSearchCategories: null,
            recentRecipes: null,
            tempMeal: null,
            foodDBSearch: null,
            currentScannedFood: null,
            workoutImageCategories: null,
            users: null,
            clientTags: null,
            subscriptions: null,
            habits: null,
            schedulableSettings: null,
            pdfExportSettings: null,
            mpInfoStubs: null,
            jwt: null,
            chats: null,
            chatMemberships: null,
            chatMessages: null,
            chatEvents: null,
            forms: null,
            formFields: null,
            userDataEntries: null,
            assessmentResults: null,
            progressPhotos: null,
			assessments: null
            //COMMENT FOR GENERATOR, DO NOT TOUCH. ADD NEW DATA REDUCER NULLING ABOVE HERE
        };
    } else {
        return dataReducerCore(state,action);
    }
}

const signupFlowReducer = (state=null,action) => {
    switch (action.type) {
        case actionTypes.ADVANCE_SIGNUP_FLOW: {
            return { ...(state || {}), ...action.data }
        }
        case actionTypes.CLEAR_INITIAL_SIGNUP:
        case actionTypes.LOGOUT_USER:
        case actionTypes.VERIFY_OAUTH_SUCCESS:
        case actionTypes.LOGIN_REQUIRED: {
            return null;
        }
        case actionTypes.EMAIL_SIGNUP_SUCCESS: {
            if(action.data.user && !_.isBlank(action.data.user.id)) {
                return null;
            }
            return state;
        }
        default:
            return state;

    }
}

const onbaordingFlowReducer = (state=null,action) => {
    switch (action.type) {
        case actionTypes.ADVANCE_ONBOARDING_FLOW: {
            return { ...(state || {}), ...action.data }
        }
        case actionTypes.CLEAR_INITIAL_SIGNUP:
        case actionTypes.LOGOUT_USER:
        case actionTypes.VERIFY_OAUTH_SUCCESS:
        case actionTypes.LOGIN_REQUIRED: {
            return null;
        }
        default:
            return state;

    }
}

const mealPlanSetupFlowReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.ADVANCE_MEAL_PLAN_SETUP: {
            return { ...action.data };
        }
        case actionTypes.CLEAR_INITIAL_SIGNUP: {
            return null;
        }
        default: {
            return state;
        }
    }
}

const workoutsCachedReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT:
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.workouts }
        }
        case actionTypes.LOG_WORKOUT_REQUEST: {
            return { ...state, [action.id]: { ...state[action.id], syncStatus: 'requesting' }}
        }
        case actionTypes.LOG_WORKOUT_FAIL: {
            return { ...state, [action.id]: { ...state[action.id], syncStatus: 'failed' }}
        }
        case actionTypes.FLAG_WORKOUT_UNSYNCED: {
            return { ...state, [action.id]: { ...state[action.id], syncStatus: 'unsynced' }}
        }
        case actionTypes.LOG_WORKOUT_SUCCESS: {
            return { ...state, [action.id]: { ...state[action.id], syncStatus: 'synced', logged: action.data.logged }}
        }
        case actionTypes.UPDATE_WORKOUT: {
            return { ...state, [action.id]: { ...state[action.id], ...action.data } }
        }
        case actionTypes.SWAP_EXERCISE_SPEC: {
            return handleNewWorkoutImageName(state,action);
        }
        case actionTypes.CLEAR_WORKOUT_REQUESTING_FLAGS: {
            if(state[action.id] && state[action.id].syncStatus === 'requesting') {
                return { ...state, [action.id]: { ...state[action.id], syncStatus: 'failed' }}
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
}

const cachedExerciseSpecificationsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT: 
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.exerciseSpecifications }
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS:
        case actionTypes.SWAP_EXERCISE_SPEC: {
            const newSpecs = action.data.exerciseSpecifications;
            Object.entries(newSpecs).forEach(([id,spec]) => {
                if(state && state[id]) {
                    spec.supersetStarted = state[id].supersetStarted;
                    spec.superset = state[id].superset;
                }
            })
            return { ...(state || {}), ...action.data.exerciseSpecifications };
        }
        case actionTypes.HANDLE_PROG_PICK_FAIL: {
            const { [action.data.id]: spec } = (state || {});
            if(spec) {
                return { ...state, [action.data.id]: { ...spec, exerciseId: action.data.exerciseId } };
            } else {
                return state;
            }
        }
        case actionTypes.UPDATE_EXERCISE_SPECIFICATION: {
            const { [action.id]: spec } = (state || {});
            if(spec) {
                return { ...state, [action.id]: { ...spec, ...action.data } };
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
}

const cachedExerciseSetsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT: 
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.exerciseSets }
        }
        case actionTypes.UPDATE_EXERCISE_SETS: {
            const newState = { ...(state || {}) };
            Object.entries(newState).forEach(([id,set]) => {
                const updates = action.data.exerciseSets[id];
                if(updates) {
                    newState[id] = { ...set, ...updates };
                }
            })
            return newState;
        }
        case actionTypes.SWAP_EXERCISE_SPEC:
        case actionTypes.WORKOUT_ACTION_SUCCESS: {
            const specIds = Object.keys(action.data.exerciseSpecifications).map(id => Number(id));
            const setIds = Object.keys(action.data.exerciseSets).map(id => Number(id));
            const toss = [];
            const newState = _.omitBy(state,set => {
                if(specIds.includes(set.exerciseSpecificationId)) {
                    if(set.logType === UserMeal.LOGGED) {
                        //warmup sets can safely be deleted and swapping already excludes logged specs in request, 
                        //so best to be consistent with server and delete
                        if(set.warmup || action.type === actionTypes.SWAP_EXERCISE_SPEC) {
                            if(setIds.includes(set.id)) {
                                toss.push(set.id);
                            } else {
                                return true;
                            }
                        } else {
                            toss.push(set.id);
                        }
                    } else {
                        return true;
                    }
                }
                return false;
            });
            const newSets = _.omitBy(action.data.exerciseSets,set => toss.includes(set.id));
            return { ...newState, ...newSets }
        }
        default: {
            return state;
        }
    }
}

const cachedExercisesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT: 
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.exercises }
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS:
        case actionTypes.SWAP_EXERCISE_SPEC: 
        case actionTypes.RESTART_MUX_UPLOAD:
        case actionTypes.CANCEL_MUX_UPLOAD_SUCCESS:
        case actionTypes.START_MUX_UPLOAD_SUCCESS: {
            if(action.data.exercises) {
                return { ...(state || {}), ...action.data.exercises }
            } else {
                return state;
            }
        }
        case actionTypes.LOAD_EX_PROGRESSION:
        case actionTypes.UPDATE_EXERCISE:
        case actionTypes.LOAD_STRENGTH_TEST_SUCCESS: {
            return { ...(state || {}), ...(action.data.exercises || {}) }
        }
        default: {
            return state;
        }
    }
}

const cachedExerciseProgressionsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT: 
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.exerciseProgressions }
        }
        case actionTypes.SWAP_EXERCISE_SPEC:
            return { ...(state || {}), ...(action.data.exerciseProgressions || {})}
        default: {
            return state;
        }
    }
}

const cachedProgexReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOAD_EX_PROGRESSION: {
            return { ...(state || {}), ...action.data.progressionExercises }
        }
        default: {
            return state;
        }
    }
}

const cachedWorkoutTemplatesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT: 
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.workoutTemplates }
        }
        default: {
            return state;
        }
    }
}

const cachedExerciseTemplatesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT: 
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.exerciseTemplates }
        }
        case actionTypes.WORKOUT_ACTION_SUCCESS:
        case actionTypes.SWAP_EXERCISE_SPEC: {
            if(action.data.exerciseTemplates) {
                return { ...(state || {}), ...action.data.exerciseTemplates }
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
}

const cachedSetTemplatesReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_WORKOUT:
        case actionTypes.RESTART_WORKOUT_SUCCESS: {
            return { ...action.data.setTemplates }
        }
        default: {
            return state;
        }
    }
}

const cachedStrengthTestsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.SUBMIT_STRENGTH_TEST_SET: {
            const { sets: currentSets=[] } = ((state || {})[action.data.exerciseId] || {});
            const newSets = [ ...currentSets.slice(0,action.data.setIndex), action.data.set ];
            return { ...(state || {}), [action.data.exerciseId]: { sets: newSets, specId: action.data.specId } }
        }
        default: {
            return state;
        }
    }
}

const cachedProgressionTestsReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.SUBMIT_PROGRESSION_TEST_SET: {
            const { sets: currentSets=[] } = ((state || {})[action.data.progressionId] || {});
            const newSets = [ ...currentSets.slice(0,action.data.setIndex), action.data.set ];
            return { ...(state || {}), [action.data.progressionId]: { sets: newSets, reps: action.data.reps, specId: action.data.specId } }
        }
        default: {
            return state;
        }
    }
}

const cachedWorkoutsCoreReducer = combineReducers({
    workouts: workoutsCachedReducer,
    exerciseSpecifications: cachedExerciseSpecificationsReducer,
    exerciseSets: cachedExerciseSetsReducer,
    exercises: cachedExercisesReducer,
    exerciseProgressions: cachedExerciseProgressionsReducer,
    progressionExercises: cachedProgexReducer,
    workoutTemplates: cachedWorkoutTemplatesReducer,
    exerciseTemplates: cachedExerciseTemplatesReducer,
    setTemplates: cachedSetTemplatesReducer,
    strengthTests: cachedStrengthTestsReducer,
    progressionTests: cachedProgressionTestsReducer
})

const cachedWorkoutsReducer = (state={}, action) => {
    if(action.type === actionTypes.CLEAR_CACHED_WORKOUTS) {
        if(state) {
            return _.omit(state,action.dates);
        } else {
            return state;
        }
    } else if(action.type === actionTypes.CLEANUP_ROUTINE_SUCCESS) {
        let { data: { cleanupDate } } = action;
        cleanupDate = moment(cleanupDate);
        const keysToRemove = _.filter(Object.keys(state),date => moment(date).isAfter(cleanupDate));
        if(keysToRemove.length === 0) {
            return state;
        } else {
            return _.omit(state,keysToRemove);
        }
    } else if(action.type === actionTypes.CLEAR_WORKOUT_REQUESTING_FLAGS) {
        if(state && !_.isEmpty(state)) {
            let newState = state;
            Object.entries(state).forEach(([date,cachedData]) => {
                const id = cachedData && cachedData.workouts && Object.keys(cachedData.workouts)[0];
                if(id) {
                    const newIdState = cachedWorkoutsCoreReducer(cachedData,{ ...action, id });
                    if(newIdState !== cachedData) {
                        newState = { ...newState, [date]: newIdState };
                    }
                }
            })
            return newState;
        } else {
            return state;
        }
    } else if(actionTypes.CACHED_WORKOUTS_ACTIONS.includes(action.type)) {
        if(action.type === actionTypes.RESTART_WORKOUT_SUCCESS && _.isEmpty(action.data)) {
            return _.omit(state,action.date);
        }
        const curState = state || {};

        if([actionTypes.CANCEL_MUX_UPLOAD_SUCCESS,actionTypes.START_MUX_UPLOAD_SUCCESS,actionTypes.RESTART_MUX_UPLOAD,actionTypes.UPDATE_EXERCISE].includes(action.type)) {
            const newState = state ? {} : null;
            Object.keys(curState).forEach(date => {
                const newIdState = cachedWorkoutsCoreReducer(curState[date],action);
                newState[date] = newIdState;
            })
            return newState;
        }

        if(curState[action.date] || action.type === actionTypes.RESTART_WORKOUT_SUCCESS || action.type === actionTypes.START_WORKOUT) {
            const newIdState = cachedWorkoutsCoreReducer(curState[action.date] || {},action);
            if(newIdState === curState[action.date]) {
                return state;
            } else {
                return { ...curState, [action.date]: newIdState }
            }
        } else {
            return state;
        }
        
    } else if(actionTypes.CLEAR_USER_ACTIONS.includes(action.type) || actionTypes.CLEAR_WIP_ACTIONS.includes(action.type)) {
        return {};
    } else {
        return state;
    }
}

const progressChartsReducer = (state={ overallLookback: 30, exerciseLookback: 30, analyticsLookback: 30, exerciseId: null },action) => {
    switch(action.type) {
        case actionTypes.UPDATE_PROGRESS_CHARTS:
            return { ...state, ...action.data };
        default: 
            return state;
    }
}

const glistPreferencesReducer = (state={ week: 'current', startDate: 0, offset: 7, batch: false },action) => {
    switch(action.type) {
        case actionTypes.LOAD_GLIST_SUCCESS:
            return { ...action.data.preferences };
        default: 
            return state;
    }
}

const myRecipesFiltersReducer = (state={ type: 'all' },action) => {
    switch(action.type) {
        case actionTypes.UPDATE_MY_RECIPES_FILTERS:
            return { ...action.data };
        default: 
            return state;
    }
}

const teamRecipesFiltersReducer = (state={ trainerId: null },action) => {
    switch(action.type) {
        case actionTypes.UPDATE_TEAM_RECIPES_FILTERS:
            return { ...action.data };
        default: 
            return state;
    }
}

const recipeDraftReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.START_EDITING_RECIPE:
            return { ...action.data };
        case actionTypes.CACHE_RECIPE_CHANGES:
            return { ...state, ...action.data };
        case actionTypes.ADD_INGREDIENT: {
            return {
                ...state,
                hasChanges: true,
                ingredients: [ ...(state.ingredients || []), action.data ]
            }
        }
        case actionTypes.CREATE_RECIPE:
        case actionTypes.UPDATE_RECIPE:
        case actionTypes.CREATE_RECIPE_OVERRIDE:
            return { ...state, hasChanges: false }
        case actionTypes.SWITCH_TO_TRAINER:
        case actionTypes.SWITCH_CLIENT_SUCCESS:
        case actionTypes.ADMIN_SWITCH_USER_SUCCESS:
        case actionTypes.CLEAR_RECIPE_DRAFT: 
            return null;
        default: 
            return state;
    }
}

const trainerSignupReducer = (state=null,action) => {
    switch (action.type) {
        case actionTypes.ADVANCE_TRAINER_SIGNUP: {
            return { ...(state || {}), ...action.data }
        }
        case actionTypes.SWITCH_TRAINER_TYPE: {
            if(state && !_.isEmpty(state)) {
                return { ...state, ...action.data };
            }
            return state;
        }
        case actionTypes.CLEAR_TRAINER_SIGNUP:
        case actionTypes.LOGOUT_USER:
        case actionTypes.LOGIN_REQUIRED: {
            return null;
        }
        default:
            return state;

    }
}

const chatCacheReducer = (state={},action) => {
    if(actionTypes.CLEAR_WIP_ACTIONS.includes(action.type)) {
        return {};
    }

    switch (action.type) {
        case actionTypes.CACHE_CHAT_MESSAGE: {
            return { ...state, [action.data.tempKey]: action.data }
        }
        case actionTypes.MESSAGE_SEND_FAILED: {
            const msg = state[action.data.tempKey];
            if(msg) {
                return { ...state, [msg.tempKey]: { ...msg, failed: true } }
            }

            return state;
        }
        case actionTypes.DISCARD_MESSAGES: {
            return _.omit(state,action.data.tempKeys);
        }
        case actionTypes.CHAT_DATA_RECEIVED:
        case actionTypes.LOAD_CHAT_MESSAGES:
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.chatMessages) {
                const persistedMessages = Object.values(action.data.chatMessages);
                return _.omitBy(state,(msg) => _.some(persistedMessages,persisted => (persisted.tempKey === msg.tempKey && persisted.chatId === msg.chatId && persisted.senderId === msg.senderId)));
            }
            return state;
        }
        case actionTypes.CLEAR_TRAINER_SIGNUP:
        case actionTypes.LOGOUT_USER:
        case actionTypes.LOGIN_REQUIRED: {
            return {};
        }
        default:
            return state;

    }
}

const currentActivityReducer = (state=null,action) => {
    if(actionTypes.CLEAR_USER_ACTIONS.includes(action.type) || actionTypes.CLEAR_WIP_ACTIONS.includes(action.type)) {
        return null;
    }

    switch(action.type) {
        case actionTypes.START_ACTIVITY:
            return { ...action.data, startedAt: moment().toISOString() };
        case actionTypes.CREATE_ACTIVITY_LOG:
        case actionTypes.DISCARD_CURRENT_ACTIVITY:
            return null;
        default:
            return state;
    }
}

const wipReducer = combineReducers({
    signupFlow: signupFlowReducer,
    onboardingFlow: onbaordingFlowReducer,
    mealPlanSetupFlow: mealPlanSetupFlowReducer,
    cachedWorkouts: cachedWorkoutsReducer,
    progressCharts: progressChartsReducer,
    glistPreferences: glistPreferencesReducer,
    myRecipesFilters: myRecipesFiltersReducer,
    teamRecipesFilters: teamRecipesFiltersReducer,
    recipeDraft: recipeDraftReducer,
    trainerSignup: trainerSignupReducer,
    chatCache: chatCacheReducer,
    currentActivity: currentActivityReducer
})

const initialRecipeOptionsReducer = (state = null, action) => {
    switch(action.type) {
        case actionTypes.INITIAL_RECIPE_OPTIONS: {
            return { ...action.data.initialRecipeOptions }
        }
        case actionTypes.UPDATE_MEAL_PLAN_SUCCESS:
        case actionTypes.UPDATE_SETTINGS:
        case actionTypes.RESET_INITIAL_RECIPE_OPTIONS: {
            return null;
        }
        case actionTypes.RECIPE_OPTIONS_CATEGORY_SUCCESS: {
            const cat = action.data.category;
            const dnpId = action.data.dnpId;
            const vals = state[dnpId];
            if(vals) {
                const { metaData } = action.data;
                const { recipeIds: newRecipeIds, meetsMacros: newMeetsMacros, pageExcluded, pageResults, page, ...rest } = metaData;
                const recipeIds = page <= 1 ? [ ...newRecipeIds ] : [ ...vals[cat].recipeIds, ...newRecipeIds ];
                const meetsMacros = page <= 1 ? [ ...newMeetsMacros ] : [ ...vals[cat].meetsMacros, ...newMeetsMacros ];
                const newVals = { ...vals, [cat]: { ...vals[cat], pageExcluded, recipeIds, meetsMacros, finished: pageResults < 5, page, ...rest } }
                return { ...state, [dnpId]: newVals }
            }
            return state;
        }
        case actionTypes.SET_INITIAL_RECIPE_FILTER: {
            const { dnpId, category, filter } = action.data;
            const vals = state[dnpId];
            if(vals) {
                const newVals = { ...vals, [category]: { ...vals[category], initialExclusions: [], pageExcluded: [], mealSearchCategoryId: filter } }
                return { ...state, [dnpId]: newVals }
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const bodyMeasurementsReducer = (state=null,action) => {
    if(actionTypes.CLEAR_USER_ACTIONS.includes(action.types) || action.type === actionTypes.CLEAR_CACHE) {
        return null;
    } else {
        switch(action.type) {
            case actionTypes.LOAD_BODY_MEASUREMENTS:
            case actionTypes.CREATE_BODY_MEASUREMENTS:
                return { ...action.data.measurements };
            default: 
                return state;
        }
    }
}

const errorLogsReducer = (state={},action) => {
    switch (action.type) {
        case actionTypes.ERROR_LOGS_LOAD: {
            const newState = paginatedResultsHandler(state,action.data.errorLogs,'records');
            return newState;
        }
        case actionTypes.DESTROY_ERROR_LOG: {
            return { ...state, records: _.filter(state.records,rec => rec.id !== action.data.id)}
        }
        case actionTypes.REFRESH_ERROR_LOGS:
        case actionTypes.CLEAR_ERROR_LOGS: {
            return {}
        }
        default:
            return state;
    }
}

const pinnedMealsReducer = (state={}, action) => {
    if(actionTypes.FULL_RELOAD_ACTIONS.includes(action.type) || 
    actionTypes.CLEAR_USER_ACTIONS.includes(action.type) || 
    action.type === actionTypes.CLEAR_CACHE) {
        return {}
    }
    switch(action.type) {
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return {}
        } 
        case actionTypes.SET_PINNED_MEALS: {
            return { ...state, [action.data.startDate]: action.data.pinnedMeals }
        }
        default:
            return state;
    }
}

const offPlanMealsReducer = (state={}, action) => {
    if(actionTypes.FULL_RELOAD_ACTIONS.includes(action.type) || 
    actionTypes.CLEAR_USER_ACTIONS.includes(action.type) || 
    action.type === actionTypes.CLEAR_CACHE) {
        return {}
    }
    switch(action.type) {
        case actionTypes.DISCARD_UNSAVED_CHANGES:
        case actionTypes.FULL_MEAL_PLAN_UPDATE: {
            return {}
        } 
        case actionTypes.CACHE_OFF_PLAN_MEALS: {
            return { ...state, [action.data.startDate]: action.data.offPlanMealMap }
        }
        default:
            return state;
    }
}

const addPeopleToMealsReducer = (state={}, action) => {
    if(actionTypes.FULL_RELOAD_ACTIONS.includes(action.type) ||
    actionTypes.CLEAR_USER_ACTIONS.includes(action.type) || 
    action.type === actionTypes.CLEAR_CACHE) {
        return {}
    }
    switch(action.type) {
        case actionTypes.DISCARD_UNSAVED_CHANGES:
        case actionTypes.FULL_MEAL_PLAN_UPDATE:
        case actionTypes.HANDLE_SHARE_MEAL_SIDE_EFFECTS: {
            return {}
        } 
        case actionTypes.ADD_PEOPLE_SELECTION: {
            return { ...state, [action.data.startDate]: action.data.addPeopleSelection }
        }
        default:
            return state;
    }
}

const chatsMetaReducer = (state=null, action) => {
    if(actionTypes.CLEAR_USER_ACTIONS.includes(action.types) || action.type === actionTypes.CLEAR_CACHE) {
        return null;
    }

    switch(action.type) {
        case actionTypes.LOAD_CHATS: {
            return _.pick(action.data,['archiveType','cutoff','loadedIds','done']);
        }
        case actionTypes.CHAT_DATA_RECEIVED: {
            if(action.data.forceChatRefresh) {
                return null;
            }
            return state;
        }
        default: 
            return state;
    }
}

const clientsScrollMeta = (state=null,action) => {
    if(actionTypes.CLEAR_USER_ACTIONS.includes(action.types) || action.type === actionTypes.CLEAR_CACHE) {
        return null;
    }

    switch(action.type) {
        case actionTypes.LOAD_CLIENTS: {
            if(action.data.meta) {
                return paginatedResultsHandler(state,action.data.meta,'ids');
            }
            return state;
        }
        case actionTypes.CREATE_CLIENT: {
            if(state && state.ids && action.data.prependIds && action.data.prependIds.length > 0) {
                return { ...state, ids: [ ...action.data.prependIds, ...state.ids ] }
            }
            return state;
        }
        default: 
            return state;
    }
}

const trainersScrollMeta = type => (state=null,action) => {
    if(actionTypes.CLEAR_USER_ACTIONS.includes(action.types) || action.type === actionTypes.CLEAR_CACHE) {
        return null;
    }

    switch(action.type) {
        case actionTypes.LOAD_ALL_TRAINERS: {
            if(action.data.meta && action.data.type === type) {
                return paginatedResultsHandler(state,action.data.meta,'ids');
            }
            return state;
        }
        case actionTypes.CREATE_CHILD_TRAINER: {
            if(type === 'teamPage' && state && state.ids && action.data.users && Object.values(action.data.users).length > 0) {
                return { ...state, ids: [ Object.values(action.data.users)[0].id, ...state.ids ] }
            }
            return state;
        }
        default: 
            return state;
    }
}

const tempDataReducer = combineReducers({
    initialRecipeOptions: initialRecipeOptionsReducer,
    bodyMeasurements: bodyMeasurementsReducer,
    errorLogs: errorLogsReducer,
    pinnedMeals: pinnedMealsReducer,
    offPlanMeals: offPlanMealsReducer,
    addPeopleToMeals: addPeopleToMealsReducer,
    chatsMeta: chatsMetaReducer,
    clientsScrollMeta: clientsScrollMeta,
    teamPageScrollMeta: trainersScrollMeta('teamPage'),
    trainerSelectScrollMeta: trainersScrollMeta('trainerSelect')
})

const historyReducer = (state={ entries: [], forwardEntries: [] },action) => {
    switch(action.type) {
        case actionTypes.HISTORY_PUSH: {
            const newState = [ ...state.entries ];
            newState.push(action.data.entry);
            return { ...state, entries: newState, lastEdit: moment().toISOString() };
        }
        case actionTypes.HISTORY_REPLACE: {
            const newState = [ ...state.entries ];
            if(newState.length === 0) {
                newState.push(action.data.entry);
            } else {
                newState[newState.length-1] = action.data.entry;
            }
            return { ...state, entries: newState, lastEdit: moment().toISOString() };
        }
        case actionTypes.PRE_HISTORY_POP: {
            const { goNum } = action.data;
            if(goNum < -1) {
                const preNum = goNum + 1;
                const cutoff = state.entries.length+preNum;
                const newForwardEntries = [ ...state.forwardEntries, ...state.entries.slice(cutoff,state.entries.length).reverse() ];
                const newEntries = state.entries.slice(0,cutoff);
                return { entries: newEntries, lastEdit: moment().toISOString(), forwardEntries: newForwardEntries };
            }
            return state;
        }
        case actionTypes.HISTORY_POP: {
            const backPath = (state.entries[state.entries.length-2] || { path: null }).path;
            const forwardPath = (state.forwardEntries[state.forwardEntries.length-1] || { path: null }).path;
            const type = (forwardPath === action.data.path && action.data.path !== backPath) ? 'forward' : 'back';
            if(type === 'forward') {
                const newForwardEntries = state.forwardEntries.slice(0,state.forwardEntries.length-1);
                const newEntries = [ ...state.entries, state.forwardEntries[state.forwardEntries.length-1] ];
                return { entries: newEntries, lastEdit: moment().toISOString(), forwardEntries: newForwardEntries };
            } else {
                const newForwardEntries = [ ...state.forwardEntries, state.entries[state.entries.length-1] ];
                const newEntries = state.entries.slice(0,state.entries.length-1);
                return { entries: newEntries, lastEdit: moment().toISOString(), forwardEntries: newForwardEntries };
            }
        }
        case actionTypes.HISTORY_CLEAR: {
            return { entries: [action.data.entry], lastEdit: moment().toISOString(), forwardEntries: [] };
        }
        case actionTypes.HISTORY_CLEAR_FORWARD: {
            return { ...state, forwardEntries: [] };
        }
        default: {
            return state;
        }
    }
}

const validActions = Object.keys(actionTypes);

const actionLogsReducer = (state=[],action) => {
    if(validActions.includes(action.type)) {
        return [ action.type, ...state.slice(0,9) ]
    }
    return state;
}

const persistentUiReducer = (state={ 
    mealPlanViewType: 'day', 
    trainerForRoutines: '', 
    trainerForHabits: '',
    tempTipDismissals: {}, 
    clientFilters: { trainerId: 'me', status: 'active', lookback: 7, sort: 'lastActiveDesc' },
    trainerFilters: { status: 'active', query: '' },
    viewLogsDate: null, videoUploads: {} },
    action) => {
    switch(action.type) {
        case actionTypes.SET_MP_VIEW_TYPE: {
            return { ...state, mealPlanViewType: action.viewType }
        }
        case actionTypes.SET_TRAINER_FOR_ROUTINES: {
            return { ...state, trainerForRoutines: action.data.trainerId }
        }
        case actionTypes.SET_TRAINER_FOR_HABITS: {
            return { ...state, trainerForHabits: action.data.trainerId }
        }
        case actionTypes.TEMP_DISMISS_TIP: {
            return { ...state, tempTipDismissals: { ...state.tempTipDismissals, [action.data.tipName]: moment().toISOString() }}
        }
        case actionTypes.SET_CLIENT_FILTERS: {
            return { ...state, clientFilters: action.data }
        }
        case actionTypes.SET_TRAINER_FILTERS: {
            return { ...state, trainerFilters: action.data }
        }
        case actionTypes.SWITCH_TO_TRAINER:
        case actionTypes.ADMIN_SWITCH_USER_SUCCESS:
        case actionTypes.SWITCH_CLIENT_SUCCESS: {
            return { ...state, viewLogsDate: null }
        }
        case actionTypes.SET_VIEW_LOGS_DATE: {
            return { ...state, viewLogsDate: action.data.date }
        }
        case actionTypes.START_MUX_UPLOAD_SUCCESS: {
            return { ...state, videoUploads: { ...state.videoUploads, [action.data.uploadMetadata.uploadId]: { ...action.data.uploadMetadata } }}
        }
        case actionTypes.CANCEL_MUX_UPLOAD_SUCCESS: {
            return { ...state, videoUploads: _.omitBy(state.videoUploads,info => (info.recordId === action.data.recordId && info.recordClass === action.data.recordClass )) }
        }
        default: {
            return state;
        }
    }
}

const trainerReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.LOGIN_USER_SUCCESS:
        case actionTypes.VERIFY_OAUTH_SUCCESS:
        case actionTypes.TSAC_SIGNUP_SUCCESS:
        case actionTypes.REFRESH_CACHE:
        case actionTypes.ADMIN_SWITCH_USER_SUCCESS:
        case actionTypes.CREATE_TRAINER:
        case actionTypes.LOAD_USER_SUCCESS: {
            if(action.data.trainerData && action.data.trainerData.trainer) {
                return { ...action.data.trainerData.trainer }
            }
            return null;
        }
        case actionTypes.UPDATE_SETTINGS: {
            if(action.data.trainerData && action.data.trainerData.trainer) {
                return { ...action.data.trainerData.trainer }
            }
            return state;
        }
        case actionTypes.LOAD_CATEGORIZE_RECIPES:
        case actionTypes.DISLIKE_RECIPE_SUCCESS:
        case actionTypes.CREATE_CHILD_TRAINER:
        case actionTypes.UPLOAD_TRAINER_LOGO: {
            if(action.data.trainer) {
                return { ...state, ...action.data.trainer }
            }
            return state;
        }
        case actionTypes.UPDATE_TBASIC_PROFILE:
        case actionTypes.LOAD_TEAM_RECIPES:
        case actionTypes.SWITCH_TTYPE_SUCCESS: {
            if(action.data.user) {
                return { ...state, ...action.data.user }
            }
            return state;
        }
        case actionTypes.UPDATE_MP_INFO_STUB: {
            if(action.data.mpNameScheme !== undefined) {
                return { ...state, mpNameScheme: action.data.mpNameScheme };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

const trainerCacheReducer = (state=null,action) => {
    switch(action.type) {
        case actionTypes.TRAINER_SUB_UPDATED: {
            if(state) {
                const { subscriptions } = state;
                return { ...state, subscriptions: { ...subscriptions, ...action.data.subscriptions } };
            }
            return state;
        }
        case actionTypes.TSAC_SIGNUP_SUCCESS: {
            return filterFullReloadData(action.data);
        }
        case actionTypes.SWITCH_CLIENT_SUCCESS: {
            if(action.trainerCache) {
                return { ...action.trainerCache }
            }
            return state;
        }
        case actionTypes.SWITCH_TO_TRAINER: {
            return null;
        }
        case actionTypes.DISMISS_TOOLTIP: {
            if(state) {
                let { user } = state;
                if(user) {
                    user = { ...user, seenTooltips: _.union(user.seenTooltips || [],[action.data.tipName]) };
                    return { ...state, user };
                }
            }
            return state;
        }
        case actionTypes.UPDATE_TBASIC_PROFILE:
        case actionTypes.SWITCH_TTYPE_SUCCESS: {
            if(state && action.data.user) {
                let { user } = state;
                if(user) {
                    user = { ...user, ...action.data.user };
                    return { ...state, user };
                }
            }
            return state;
        }
        default: {
            if(actionTypes.LOGIN_ACTIONS.includes(action.type) && action.data.trainerCache) {
                return filterFullReloadData(action.data.trainerCache);
            } else if((action.type === actionTypes.LOAD_USER_SUCCESS || actionTypes.FULL_RELOAD_ACTIONS.includes(action.type)) && 
            state && state.user && action.data.user && action.data.user.id === state.user.id) {
                return null;
            }
            return state;
        }
    }
}

const trainerDataReducerCore = combineReducers({
    trainer: trainerReducer,
    cache: trainerCacheReducer
})

const trainerDataReducer = (state,action) => {
    if(actionTypes.CLEAR_TRAINER_ACTIONS.includes(action.type) || action.type === actionTypes.CLEAR_CACHE) {
        return { 
            trainer: null,
            cache: null
        };
    } else {
        return trainerDataReducerCore(state,action);
    }
}

const frontendAbTestReducer = (state={ version: _.random(1) },action) => {
    return state;
}

const rootReducer = combineReducers({
    actionLogs: actionLogsReducer,
    trainerData: trainerDataReducer,
    data: dataReducer,
    ui: uiReducer,
    tempData: tempDataReducer,
    wip: wipReducer,
    analytics: analyticsReducer,
    history: historyReducer,
    persistentUi: persistentUiReducer,
    frontendAbTest1: frontendAbTestReducer
});

export default rootReducer;