import random from 'lodash/random';
import get from 'lodash/get';
import uniqWith from 'lodash/uniqWith';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import minBy from 'lodash/minBy';
import capitalize from 'lodash/capitalize';
import difference from 'lodash/difference';
import some from 'lodash/some';
import every from 'lodash/every';
import uniqBy from 'lodash/uniqBy';
import union from 'lodash/union';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import values from 'lodash/values';
import without from 'lodash/without';
import compact from 'lodash/compact';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';
import invert from 'lodash/invert';
import upperFirst from 'lodash/upperFirst';
import lowerFirst from 'lodash/lowerFirst';
import startCase from 'lodash/startCase';
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import flatMap from 'lodash/flatMap';
import findIndex from 'lodash/findIndex';
import chunk from 'lodash/chunk';
import remove from 'lodash/remove';
import shuffle from 'lodash/shuffle';
import groupBy from 'lodash/groupBy';
import reverse from 'lodash/reverse';
import intersectionBy from 'lodash/intersectionBy';
import maxBy from 'lodash/maxBy';
import fromPairs from 'lodash/fromPairs';
import orderBy from 'lodash/orderBy';
import set from 'lodash/set';
import mapKeys from 'lodash/mapKeys';
import zipObject from 'lodash/zipObject';
import { Children, isValidElement } from 'react'
import { validResponses } from 'components/LoadingHOC';
import { MacroParams } from './macro-param';

export { random, get, uniqWith, pick, minBy, capitalize, difference, some, every, uniqBy, orderBy, zipObject,
    union, intersection, uniq, filter, values, without, compact, find, pickBy, sortBy, fromPairs,
    omitBy, omit, mapValues, mapKeys, flatten, invert, upperFirst, lowerFirst, startCase, keyBy, reduce, 
    concat, flatMap, findIndex, chunk, remove, shuffle, groupBy, reverse, intersectionBy, maxBy, set };

export const orderedArrayPick = (ids,arr,key='id') => {
    const newArr = [];
    ids.forEach(id => {
        const elem = find(arr,item => (item[key] === id));
        if(elem) newArr.push(elem);
    })
    return newArr;
}

export const macroToHealthkitKey = {
    calories: 'nutrition.calories',
    protein: 'nutrition.protein',
    fat: 'nutrition.fat.total',
    carbs: 'nutrition.carbs.total',
    fiber: 'nutrition.dietary_fiber'
}

export const arraysEqual = (arr1,arr2) => {
    const overlap = intersection(arr1,arr2);
    return (overlap.length === arr1.length && overlap.length === arr2.length);
}

export const getIapStore = () => (window.CdvPurchase && window.CdvPurchase.store)

export const getIapPlatform = () => {
    const Platform = window.CdvPurchase.Platform;

    if(isAndroid()) {
        return Platform.GOOGLE_PLAY;
    }

    return Platform.APPLE_APPSTORE;
}

export const isPrimaryDomain = () => (window.isPrimaryDomain === 'true');
export const allowDirectSignup = () => (window.allowDirectSignup === 'true');
export const packagePrefix = () => window.nativePackagePrefix;
export const androidPackageName = () => window.androidPackageName;
export const iosPackageName = () => window.iosPackageName;
export const iosAppId = () => window.iosAppId;
export const excludeProgressPhoto = () => window.excludeProgressPhoto === 'true';

export const signedInAsClient = (trainer,user) => (trainer && user && trainer.id !== user.id);
export const adminSignedInAsUser = user => {
    const adminId = localStorage.getItem('adminId');

    if(isBlank(adminId) || isBlank(user)) {
        return false;
    }

    return Number(adminId) !== user.id;
}

export const listToSentence = list => {
    const newList = noBlanks(list);
    if(newList.length <= 2) {
        return newList.join(' & ');
    }

    let str = newList.slice(0,newList.length-1).join(', ');
    return `${str}, & ${newList[newList.length-1]}`;
}

export const weekdaysToSentence = wdays => {
    const daynames = moment.weekdaysShort();

    return listToSentence(wdays.map(wday => daynames[wday]));
}

export const appVersion = () => {
    return (!isBlank(window.appVersion) && isNumeric(window.appVersion)) ? Number(window.appVersion) : 0;
}

export const hasNativeBeep = () => (window.isCordova && window.timerBeep && appVersion() >= 230)
export const hasAppsFlyer = () => (window.isCordova && window.plugins && window.plugins.appsFlyer && (appVersion() < 225|| appVersion() >= 245))
export const hasAppleHealth = () => (window.isCordova && appVersion() >= 235 && isIOS() && !!navigator.health);
export const hasStepCounter = () => (window.isCordova && appVersion() >= 235 && isAndroid() && !!window.stepcounter);

export const noBlanks = arr => filter(arr,i => !isBlank(i))

export const mixpanelTrack = (evtName,props) => {
    if(window.mixpanel && typeof window.mixpanel.track === 'function') {
        props = { ...(props || {}), domain: window.location.host }
        window.mixpanel.track(evtName,props);
    }
}

export const mixpanelPeopleSet = (...args) => {
    const mixpanel = window.mixpanel;
    if(mixpanel && mixpanel.people && typeof mixpanel.people.set === 'function') {
        mixpanel.people.set(...args);
    }
}

export const recsToFront = (records,ids) => {
    const frontRecs = compact(ids.map(id => find(records, rec => rec.id === id)));

    return [ ...frontRecs, ...filter(records,rec => !ids.includes(rec.id)) ];
}

export const isMixpanelDeviceRegistered = () => {
    const { mixpanelDeviceId } = window;

    return !isBlank(mixpanelDeviceId) && mixpanelDeviceId !== 'nil' && mixpanelDeviceId !== 'null'
}

export const mergeExisting = (targ,source) => {
    return mapValues(targ,(value,key) => (key in source ? source[key] : value)) 
}

export const mergeDefaults = (targ,defaults) => {
    const newTarg = { ...targ };
    Object.entries(defaults).forEach(([k,v]) => {
        if(isBlank(newTarg[k])) {
            newTarg[k] = v;
        }
    })
    return newTarg;
}

export const handleBlankStr = (val,passStr) => isBlank(val) ? '' : passStr;
export const fallbackText = (text,fallback) => isBlank(text) ? fallback : text;

export const isHexColor = str => /^#(?:[0-9a-f]{3}){1,2}$/i.test(str)

export const arrToObj = (arr,predicate) => {
    const obj = {};
    arr.forEach(entry => {
        obj[entry] = predicate(entry)
    });
    return obj;
}

export const formatTimePassed = (mom,t) => {
    const minDiff = moment().diff(mom,'minutes');
    if(minDiff < 1) {
        return t('just now')
    } else if(minDiff < 60) {
        return `${minDiff} ${t('mins')}`
    } else {
        const hrDiff = moment().diff(mom,'hours');
        if(hrDiff < 24) {
            return `${hrDiff} ${t('hrs')}`
        } else if(hrDiff < 48) {
            return t('yesterday')
        } else {
            return mom.format('MMM Do')
        }
    }
}

export const weekTitle = (week,mpNameScheme) => (mpNameScheme || 'Week {{number}}').replace(/\{\{number\}\}/g,`${Number(week)+1}`);

export const mapSelectOptions = (col,predicate) => {
    return col.map(val => (isBlank(val) ? { text: '', value: ''} : predicate(val)))
}

export const collectorize = (arr,stringify) => arr.map(val => ({ text: (stringify ? `${val}` : val), value: val }))

export const isInternalRequest = (historyEntries) => {
    return historyEntries.length > 1 || document.referrer.includes(window.origin);
}

export const pickAll = (obj,keys) => {
    let newObj = {};
    keys.forEach(key => {
        if(key in obj) {
            newObj[key] = obj[key];
        } else {
            newObj[key] = undefined;
        }
    })
    return newObj;
}

export const setAttrIndif = (obj,attrHash) => {
    Object.entries(attrHash).forEach(([attr,val]) => {
        if(attr in obj) {
            if(typeof obj[attr] === 'function') {
                obj[attr](val);
            } else {
                obj[attr] = val;
            }
        } else {
            obj[attr] = val;
        }
    })
}

export const getAttrIndif = (obj,attrs,all=false) => {
    let retObj = {};
    attrs.forEach(attr => {
        if(all || attr in obj) {
            if(typeof obj[attr] === 'function') {
                retObj[attr] = obj[attr]();
            } else {
                retObj[attr] = obj[attr];
            }
        }
    })
    return retObj;
}

export const findWithFKs = (obj,fk,fkVals) => {
    return find(Object.values(obj),(record) => (fkVals.includes(record[fk])))
}

export const selectWithFKs = (obj,fk,fkVals) => {
    return pickBy(obj,(record,id) => (fkVals.includes(record[fk])))
}

export const deleteWithFKs = (obj,fk,fkVals) => {
    return omitBy(obj,(record,id) => (fkVals.includes(record[fk])))
}

export const hashSum = (hashes) => {
    const newHash = {};
    for(let hash of hashes) {
        Object.entries(hash).forEach(([key,val]) => {
            if(newHash[key]) {
                newHash[key] = newHash[key] + Number(val);
            } else {
                newHash[key] = Number(val);
            }
        })
    }
    return newHash;
}

export const multiplyHash = (hash,num,round=true) => {
    return mapValues(hash,hashNum => (round ? Math.round(Number(hashNum)*num) : Number(hashNum)*num));
}

export const arrayChangeHandlerCreator = (setFieldValue,setFieldTouched,submit) => (e) => {
    setFieldValue(e.target.name,arrayForForm([].slice.call(e.target.selectedOptions).map(option => option.value)));
    setFieldTouched && setFieldTouched(e.target.name);
    submit && setTimeout(submit,0);
}

export const arrayForForm = (array) => {
    let newArray = without(array.map(elem => {
        if(Array.isArray(elem)) {
            return arrayForForm(elem);
        } else if(elem && typeof elem === 'object') {
            return parseObjForForm(elem);
        } else if(isBlank(elem)) {
            return '';
        } else {
            return elem;
        }
    }),'');
    if(newArray.length === 0) {
        return [''];
    } else {
        return newArray;
    }
}

function hourAndMinChars(moment,t=null) {
    const chars = [];
    let alwaysZeroPadMins = false;
    const hStr = t ? `[${t('h')}]` : '';
    const mStr = t ? `[${t('min_init')}]` : '';
    if(moment.hour() >= 10) {
        chars.push(`HH${hStr}`);
        alwaysZeroPadMins = true;
    } else if(moment.hour() > 0) {
        chars.push(`H${hStr}`);
        alwaysZeroPadMins = true;
    }

    if(alwaysZeroPadMins || moment.minutes() >= 10) {
        chars.push(`mm${mStr}`);
    } else {
        chars.push(`m${mStr}`);
    }

    return chars;
}

export function timeLabel(seconds,t) {
    if(seconds >= 3600) {
        return `${t('hrs')}:${t('Mins').toLowerCase()}:${t('Secs').toLowerCase()}`;
    } else {
        return `${t('Mins').toLowerCase()}:${t('Secs').toLowerCase()}`;
    }
}

export function stopwatchFormat(seconds) {
    const mmt = moment.utc(seconds*1000);
    let chars = hourAndMinChars(mmt);
    chars.push(`ss`);

    return mmt.utc().format(chars.join(':'));
}

export const imageFormFilter = (defName='defaultImage',name='image') => values => {
    const vals = omit(values,[defName])
    if(isBlank(vals[name])) {
        return omit(vals,[name])
    }

    return { ...vals, [name]: dataURItoBlob(vals[name]) }
}

export function parseStopwatchFormat(setVal) {
    const segs = setVal.split(':');
    let seconds = 0;
    segs.reverse().forEach((seg,index) => {
        let secs = Number(seg);
        if(index === 1) {
            secs = secs*60;
        } else if(index === 2) {
            secs = secs*3600;
        }
        seconds += secs;
    })
    return seconds;
}

export function setTimeFormat(seconds,t) {
    const mmt = moment.utc(seconds*1000);
    let chars = hourAndMinChars(mmt,t);
    const sStr = `[${t('s')}]`;

    if(chars.length === 0) {
        chars.push(`s${sStr}`);
    } else {
        chars.push(`ss${sStr}`);
    }

    return mmt.utc().format(chars.join(''));
}

export function flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

export const parseObjForForm = (obj) => {
    Object.entries(obj).forEach(([key,val]) => {
        if(Array.isArray(val)) {
            obj[key] = arrayForForm(val);
        } else if(obj[key] === null || (key in obj && obj[key] === undefined)) {
            obj[key] = '';
        } else if(typeof val === 'object') {
            obj[key] = parseObjForForm(val);
        }
    })
    return obj;
}

export function defaultValues(target,defaults) {
    Object.entries(defaults).forEach(([key,val]) => {
        if(isBlank(target[key])) {
            target[key] = val;
        }
    })
}

export function publicUrl() {
    let base = process.env.PUBLIC_URL || '/';
    if(base.match(/\/$/)) {
        return base;
    } else {
        return base + '/';
    }
}

export function todayParam(dateFormat) {
    return moment().format(dateFormat);
}

export function nextWeekCollection(dateFormat,isOffset=false,displayFormat='ddd MMM Do') {
    const today = moment();
    let res = []
    for(let i=1; i <= 7; i++) {
        const txt = today.format(displayFormat);
        const val = isOffset ? i-1 : today.format(dateFormat);
        res.push({value: val, text: txt});
        today.add(1,'day');
    }
    return res;
}

export function lastWeekCollection(dateFormat,displayFormat='ddd MMM Do') {
    const today = moment().subtract(6,'days');
    let res = []
    for(let i=1; i <= 7; i++) {
        const txt = today.format(displayFormat);
        const val = today.format(dateFormat);
        res.unshift({value: val, text: txt});
        today.add(1,'day');
    }
    return res;
}

export function weekdaysCollection() {
    return moment.weekdays().map((dayName,index) => ({ text: dayName, value: index }));
}

export function nextWeekDates(startDate) {
    let res = [];
    for(let i=0; i < 7; i++) {
        const date = startDate.clone().add(i,'days');
        res.push(date);
    }
    return res;
}

export function weekdays(startDay=0) {
    const weekdays = [0,1,2,3,4,5,6];
    startDay = startDay % 7;

    return (weekdays.slice(startDay,7).concat(weekdays.slice(0,startDay)));
}

export function shortWdaysDesc(wdays,t) {
    if(wdays.length === 0) {
        return t('No Days')
    }

    const daynames = moment.weekdaysMin();

    return wdays.sort().map(wday => daynames[wday]).join('|')
}

export function wday(date) {
    return date.isoWeekday()%7;
}

export function weekStart(inDate,wday=0) {
    const curDate = inDate.clone().startOf('day');
    let start = curDate.clone().day(wday);
    if(start.isAfter(curDate)) {
        start.subtract(7,'days');
    }
    return start;
}

export function weekEnd(date,wday=0) {
    let end = weekStart(date,wday);
    end.add(6,'days');
    return end;
}

export function parseYupErrors(validationError) {
    let result = {};
    for (let error of validationError.inner) {
        result[error.path] = error.message;
    }
    return result;

}

export function unitWeight(weight,units) {
    if(units === 1) {
        return roundToF(weight/2.2,0.001);
    } else {
        return weight;
    }
}

export const getUnitStr = (unitPreference,t) => {
    return unitPreference === 1 ? t('kgs') : t('lbs');
}

export function numberWithCommas(x) {
    return x.toLocaleString();
}

export function easeInNextDelay(t,n,r){const a=t/n,e=3*a*Math.pow(1-a,2)*0+3*Math.pow(a,2)*(1-a)*1+Math.pow(a,3)*1,u=e*r,c=(t-1)/n,o=3*c*Math.pow(1-c,2)*0+3*Math.pow(c,2)*(1-c)*1+Math.pow(c,3)*1,s=o*r;return u-s}

function mapConversionResults(result,valMap) {
    if(valMap) {
        for(let [key,val] of Object.entries(valMap)) {
            if(result[key] || result[key] === 0) {
                result[val] = result[key];
                delete result[key];
            }
        }
    }
    return result;
}

function mapConversionUnits(units,valMap) {
    if(valMap) {
        for(let [key,val] of Object.entries(valMap)) {
            if(units[val] || units[val] === 0) {
                units[key] = units[val];
                delete units[val];
            }
        }
    }
    return units;
}

export const inchesToFtIn = inches => {
    const rd = Math.round(inches);
    const ft = Math.floor(rd/12);
    const inchesLeft = rd % 12;

    return `${ft}' ${inchesLeft}"`;
}

export function metricToImperial(units,valMap) {
    units = mapConversionUnits({ ...units },valMap);
    let result = {};
    if(isNumeric(units.cm)) {
        let inches = units.cm/2.54;
        result.feet = Math.floor(inches/12.0);
        result.inches = Math.round(inches % 12)
    }

    if(isNumeric(units.kgs)) {
        result.lbs = roundToF(units.kgs*2.2,0.1);
    }

    mapConversionResults(result,valMap);

    return result;
}

export function imperialToMetric(units, valMap) {
    units = mapConversionUnits({ ...units },valMap);
    let result = {};
    if(isNumeric(units.inches)) {
        result.cm = units.inches*2.54;
    }

    if(isNumeric(units.feet)) {
        result.cm = result.cm || 0;
        result.cm += units.feet*12*2.54;
    }

    if(result.cm) {
        result.cm = Math.round(result.cm);
    }

    if(isNumeric(units.lbs)) {
        result.kgs = roundToF(units.lbs/2.2,0.1);
    }

    mapConversionResults(result,valMap);

    return result;
}

export function formikHandlerCreator(_this,setState,apiCall,valuesFilter) {
    return function(values, { setSubmitting, isSubmitting, ...formikBag }) {
        if(valuesFilter) {
            values = valuesFilter(values);
        }
        if(!isSubmitting) {
            setState('REQUEST');
            const handleApiResponse = (response) => {
                if(!response.isCanceled) {
                    if(validResponses.includes(response.status)) {
                        setState(response.status,formikBag,response.data,values);
                        if(!_this.unmounted)
                            setSubmitting(false);
                    } else {
                        if(response.data)
                            throw response.data;
                        else
                            throw response;
                    }
                }
            }
            _this.loadPromise = makeCancelable(apiCall(values));
            _this.loadPromise.promise.then(handleApiResponse).catch(handleApiResponse);
        }
    }
}

export function isIOS() {
    return (window.cordova && window.cordova.platformId === 'ios');
}

export function isAndroid() {
    return (window.cordova && window.cordova.platformId === 'android');
}

export function isPWA() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    // On some iOS devices, 'standalone' mode is not recognized, so we check for navigator.standalone
    const isIOSStandalone = ('standalone' in window.navigator) && (window.navigator.standalone);

    return isStandalone || isIOSStandalone;
}

export function isOldApp() {
    return window.appPlatform === 'ios' || window.appPlatform === 'android';
}

export function isOldIosApp() {
    return window.appPlatform === 'ios';
}

export function platformString() {
    if(isIOS()) {
        return 'ios';
    } else if(isAndroid()) {
        return 'android';
    } else {
        return 'web';
    }
}

export function isMobile() {
    return window.matchMedia("only screen and (max-width: 992px)").matches;
}

export function oauthProviderString(provider) {
    if(provider === 'google_oauth2') {
        return 'Google';
    } else if(!isBlank(provider)) {
        return capitalize(provider);
    } else {
        return 'Email';
    }
}

export function getAppPromptType() {
    if(window.hideGetAppPrompt === 'true') {
        return 'pwa';
    } else {
        return 'native';
    }
}

export function showGetAppPrompt(user,isTest=false) {
    return (isMobile() || isTest) && (!user || user.shouldShowGetAppPrompt());
}

export function isFalsy(obj) {
    return ((!obj && obj !== 0) || obj === '')
}

export function isBlank(obj) {
    return ((!obj && obj !== 0 && obj !== false) || obj === '')
}

export function xArray(x){
    return Array.from({length: x}, (_, i) => i + 1);
}

export function isNumeric(obj) {
    return (!isNaN(obj) && obj !== '' && obj !== null && obj !== false && obj !== true && obj !== undefined)
}

export function castNumericInput(val) {
    return isBlank(val) ? '' : Number(val);
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

export function roundToF(num,float) {
    return Math.round(num/float)/(1/float);
}

export function camelize(text) {
    return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function(match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();        
    });
}

export const macroHashSummary = (t,macroHash,showCals=true,showFiber=false) => {
    return `${showCals ? `${macroHash.calories + t('cals')}, ` : ''}${macroHash.protein + t('protein_abbr')}, ${macroHash.fat + t('fat_abbr')}, ${macroHash.carbs + t('carb_abbr')}${showFiber ? `, ${macroHash.fiber + t('fiber')}` : ''}`
}

export const macroTargetsSummary = (t,targets,delimiter=', ',abbr=false) => {
    const cals = Math.round(targets.calories);
    let str = `${cals}${t('cals')}`
    const macros = pick(targets,['protein','carbs','fat']);
    Object.entries(macros).forEach(([macro,params]) => {
        const [amount,dir] = params;
        const suffix = abbr ? t(macro)[0] : `${t('g')} ${t(macro)}`;
        str = `${str}${delimiter}${dir > 0 ? '>' : '<'}${Math.round(amount)}${suffix}`
    })
    return str;
}

export function decamelize(s) {
    const removeLeading = s[0] !== '_';
    let newS = s.replace(/\.?([A-Z])/g, function (x,y){return "_" + y.toLowerCase()});
    if(removeLeading) {
        newS = newS.replace(/^_/, "");
    }
    return newS;
}

export function isEmpty(obj) {
    return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

const selfOrParentEquals = (el,toCheck) => {
    if(!el)
        return false;

    if(el === toCheck)
        return true;
    
    return selfOrParentEquals(el.parentElement,toCheck);
}

export function elemIsAtTop(el) {
    const rect = el.getBoundingClientRect();
    const child = document.elementFromPoint(rect.left+2,rect.top+2);
    return selfOrParentEquals(child,el);
}

export function abbrText(str,max, fallback = '') {
    if(!str || str === '') {
        return fallback;
    }

    if(str.length <= max) {
        return str;
    }

    return str.slice(0,max-3) + '...';
}

const buildRecord = (recs,obj,classToUse,uniqueBy) => {
    let record = new classToUse(obj);
    if(!uniqueBy) {
        recs.push(record);
    } else if(!some(recs,(rec) => uniqueBy(record,rec))) {
        recs.push(record);
    }
}

export function recordsFromIds(records, ids, classToUse, uniqueBy) {
    if(!records || isEmpty(records) || !ids || ids.length === 0) {
        return [];
    }
    let recs = [];
    for(let id of ids) {
        if(records[id]) {
            buildRecord(recs,records[id],classToUse,uniqueBy);
        }
    }
    return recs;
}

export function parseDishStubs(sideDishStubs) {
    return sideDishStubs.split('-').map(rmStub => rmStub.split('_').map(num => Number(num)))
}

export function serializeDishStubs(sideDishStubs) {
    return sideDishStubs.map(stub => stub.join('_')).join('-');
}

export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

export function recordsFromPartials(records,partials,classToUse,uniqueBy) {
    if(!records || isEmpty(records) || !partials || isEmpty(partials)) {
        return [];
    }
    let recs = [];
    for(let partial of partials) {
        if(records[partial.id]) {
            buildRecord(recs,{ ...records[partial.id], ...partial },classToUse,uniqueBy);
        }
    }
    return recs;
}

export const recordsFromObjects = (objects,classToUse) => {
    return objects.map((obj,index) => {
        return new classToUse(obj);
    })
}

export const recordHashFromArray = (recordArr) => {
    let result = {};
    for(let record of recordArr) {
        result[record.id] = record;
    }
    return result;
}

export const pageAccessedByReload = () => (
    (window.performance.navigation && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
);

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;
  
    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
        error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
      );
    });
  
    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true;
      },
    };
  };

  export const getPath = function (obj, path, def) {

	/**
	 * If the path is a string, convert it to an array
	 * @param  {String|Array} path The path
	 * @return {Array}             The path array
	 */
	var stringToPath = function (path) {

		// If the path isn't a string, return it
		if (typeof path !== 'string') return path;

		// Create new array
		var output = [];

		// Split to an array with dot notation
		path.split('.').forEach(function (item, index) {

			// Split to an array with bracket notation
			item.split(/\[([^}]+)\]/g).forEach(function (key) {

				// Push to the new array
				if (key.length > 0) {
					output.push(key);
				}

			});

		});

		return output;

	};

	// Get the path as an array
	path = stringToPath(path);

	// Cache the current object
	var current = obj;

	// For each item in the path, dig into the object
	for (var i = 0; i < path.length; i++) {

		// If the item isn't found, return the default (or null)
		if (!current[path[i]]) return def;

		// Otherwise, update the current  value
		current = current[path[i]];

	}

	return current;

};

export function delay(t, v, f) {
    return new Promise(function(resolve) { 
        setTimeout(() => {
            f && f();
            resolve(v);
        }, t)
    });
 }

 /**
 * Given `this.props.children`, return an object mapping key to child.
 *
 * @param {*} children `this.props.children`
 * @return {object} Mapping of key to child
 */
export function getChildMapping(children, mapFn) {
    let mapper = child => (mapFn && isValidElement(child) ? mapFn(child) : child)
  
    let result = Object.create(null)
    if (children)
      Children.map(children, c => c).forEach(child => {
        // run the map function here instead so that the key is the computed one
        result[child.key] = mapper(child)
      })
    return result
  }
  
  /**
   * When you're adding or removing children some may be added or removed in the
   * same render pass. We want to show *both* since we want to simultaneously
   * animate elements in and out. This function takes a previous set of keys
   * and a new set of keys and merges them with its best guess of the correct
   * ordering. In the future we may expose some of the utilities in
   * ReactMultiChild to make this easy, but for now React itself does not
   * directly have this concept of the union of prevChildren and nextChildren
   * so we implement it here.
   *
   * @param {object} prev prev children as returned from
   * `ReactTransitionChildMapping.getChildMapping()`.
   * @param {object} next next children as returned from
   * `ReactTransitionChildMapping.getChildMapping()`.
   * @return {object} a key set that contains all keys in `prev` and all keys
   * in `next` in a reasonable order.
   */
  export function mergeChildMappings(prev, next) {
    prev = prev || {}
    next = next || {}
  
    function getValueForKey(key) {
      return key in next ? next[key] : prev[key]
    }
  
    // For each key of `next`, the list of keys to insert before that key in
    // the combined list
    let nextKeysPending = Object.create(null)
  
    let pendingKeys = []
    for (let prevKey in prev) {
      if (prevKey in next) {
        if (pendingKeys.length) {
          nextKeysPending[prevKey] = pendingKeys
          pendingKeys = []
        }
      } else {
        pendingKeys.push(prevKey)
      }
    }
  
    let i
    let childMapping = {}
    for (let nextKey in next) {
      if (nextKeysPending[nextKey]) {
        for (i = 0; i < nextKeysPending[nextKey].length; i++) {
          let pendingNextKey = nextKeysPending[nextKey][i]
          childMapping[nextKeysPending[nextKey][i]] = getValueForKey(
            pendingNextKey
          )
        }
      }
      childMapping[nextKey] = getValueForKey(nextKey)
    }
  
    // Finally, add the keys which didn't appear before any key in `next`
    for (i = 0; i < pendingKeys.length; i++) {
      childMapping[pendingKeys[i]] = getValueForKey(pendingKeys[i])
    }
  
    return childMapping
  }

export function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export function parseGchartToChartJs(gchartData,optionMap=[],t=(val) => (val)) {
    const labelRow = gchartData[0];
    const dataRows = gchartData.slice(1,gchartData.length);
    let datasets = [];
    for(let i=1; i < labelRow.length; i++) {
        datasets.push({ data: dataRows.map(datum => ({ t: datum[0], y: datum[i] })), label: t(labelRow[i]), pointStyle: 'line', ...(optionMap[i-1] || {}) });
    }
    return { datasets }    
}

export const macroWarningsCheckFor = user => ({ row, t, date }) => {
    const [,protein,fat,carbs,fiber,alcohol] = row;
    const macroCalHash = {protein,fat,carbs,fiber,alcohol};
    Object.entries(macroCalHash).forEach(([macro,cals]) => (macroCalHash[macro] = (cals || 0)));
    const macroHash = MacroParams.gramsFor({ calories: Object.values(macroCalHash).reduce((agg,cur) => agg+cur,0), protein, fat, carbs, fiber, alcohol });
    return user.dnpOn(date).macroWarningsFor(macroHash,100,t);
}

export function stringToColor(str) {

    if(isBlank(str)) {
        return '#19a4cf';
    }

    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (let i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export function hexToHSL(H) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (H.length === 4) {
      r = "0x" + H[1] + H[1];
      g = "0x" + H[2] + H[2];
      b = "0x" + H[3] + H[3];
    } else if (H.length === 7) {
      r = "0x" + H[1] + H[2];
      g = "0x" + H[3] + H[4];
      b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
  
    if (delta === 0)
      h = 0;
    else if (cmax === r)
      h = ((g - b) / delta) % 6;
    else if (cmax === g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;
  
    h = Math.round(h * 60);
  
    if (h < 0)
      h += 360;
  
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
  
    return { h, s, l }
  }


  export const setAppColor = (hsl,pad=true) => {
    Object.entries(hsl).forEach(([k,v]) => document.documentElement.style.setProperty(`--primary-${k}`,pad ? ` ${v}${k !== 'h' ? '%' : ''}` : v))
}

export const roughSizeOfObj = (obj) => {
    return (JSON.stringify(obj || {}).length*2)/1000;
}

export const clearCurrentUser = () => {
    sessionStorage.removeItem('currentUserId');
    sessionStorage.removeItem('currentFocusDate');
}
