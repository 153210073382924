import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from "components/Routing";
import RouteModal, { ModalHeader, ModalFooter } from 'components/Modal';
import { getAppPath, scannedFoodLogModalPostfix } from 'config/paths';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { scannedFoodSelector, trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { getFoodFromBarcode, clearScannedFood, createFoodWeight } from 'redux/actions';
import { supportEmail } from 'config/settings';
import * as Yup from 'yup';
import PlusMinusToggle, { PlusMinusField } from 'components/PlusMinusToggle';
import { SelectInput } from 'components/TextInput';
import { FullSvgMacroChart } from './MacroCircleSvg';
import FormikWrapper from 'components/FormikWrapper';
import { FoodWeightFieldsRow } from './FoodForm';
import { formikInputProps } from 'components/Form';
import { FatsecretWatermark } from './Utilities';
import { SlightEmphasisIconNote } from 'components/Typography';
import BarcodeScannerWrapper from './BarcodeScannerWrapper';

export const launchBarcodeScanner = ({ setScanning, basePath, modalPostfix, t, history, clearScannedFood, setScannedParams }) => () => {
    const modalPath = basePath + modalPostfix;
    let params = { };

    clearScannedFood();
    setScannedParams({});
    setScanning(true);
    history.push(modalPath);

    if(window.isCordova) {
        const scanner = window.cordova.plugins.barcodeScanner;
        scanner.scan(result => {
            if(result.cancelled) {
                setTimeout(() => {
                    setScanning(false);
                    if(window.location.pathname.includes(modalPostfix)) {
                        history.goBack();
                    }
                },100);
            } else {
                params.barcode = result.text;
                params.barcodeType = result.format;
                setScannedParams(params);
                setScanning(false);
            }
    
        }, error => {
            if(error === 'Illegal access') {
                alert(t("Scanning failed because camera permissions were denied"));
            } else {
                alert(t("Scanning failed due to an error",{ error: error, email: supportEmail}));
            }
        },
        {
            preferFrontCamera : false, // iOS and Android
            showFlipCameraButton : true, // iOS and Android
            showTorchButton : true, // iOS and Android
            torchOn: false, // Android, launch with the torch switched on (if available)
            saveHistory: false, // Android, save scan history (default false)
            prompt: "Place barcode inside the scan area", // Android
            resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            formats: "UPC_A,UPC_E,EAN_8,EAN_13", // default: all but PDF_417 and RSS_EXPANDED
            orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
            disableAnimations: true, // iOS
            disableSuccessBeep: true // iOS and Android
        })
    }
}

const SubmitSuccess = ({ close }) => {
    close();
    return '';
}

const SubmitDefault = ({ confirmTitle }) => {

    return (
        <Button tag='button' type="submit" rounded color="primary" noShadow >{confirmTitle}</Button>
    )
}

const FoodWeightSubmitDefault = ({ food, setSeq, t }) => {

    return (
        <React.Fragment>
            <Button rounded outlined color="primary" noShadow onClick={() => setSeq(food.defaultSeq())} >{t('Cancel')}</Button>
            <Button tag='button' type="submit" rounded color="primary" noShadow className="ml5" >{t('Create unit')}</Button>
        </React.Fragment>
    )
}

const FoodWeightSubmitLOC = loadingContainer({
    "DEFAULT": FoodWeightSubmitDefault,
    "SUCCESS": FoodWeightSubmitDefault
})

const SubmitLOC = loadingContainer({
    "DEFAULT": SubmitDefault,
    "SUCCESS": SubmitSuccess
})

let FoodWeightForm = ({ food, createFoodWeight, setSeq, trainer, user }) => {
    const personalUser = trainer || user;
    const { t } = useTranslation();

    
    if(personalUser.canCreateFoods()) {
        return (
            <FormikWrapper 
                initialValues={ { id: food.id, amount: 1, measurement: '', grams: '' } }
                validationSchema={Yup.object().shape({
                    amount: Yup.number().moreThan(0).required(),
                    measurement: Yup.string().required(),
                    grams: Yup.number().moreThan(0).required()
                })}
                submit={createFoodWeight}
                successCallback={({ status,formikBag,data }) => {
                    setSeq(data.seq)
                }}
                initialErrors={{}} 
                render={(formikProps) => {
                    const { handleSubmit, submitState, values } = formikProps;
                    const macros = food.macroHashForGrams((_.isBlank(values.grams) || values.grams <= 0) ?  100 : values.grams);
                    return (
                        <form onSubmit={handleSubmit} className="text-center">
                            {!_.isBlank(values.grams) && (<div key="chart" className="pt10">
                                <FullSvgMacroChart macros={macros} />
                            </div>)}
                            <div>
                                <FoodWeightFieldsRow 
                                    baseName=''
                                    {..._.pick(formikProps,formikInputProps)}
                                />
                            </div>
                            <div className="pa20 text-center">
                                <FoodWeightSubmitLOC loadState={submitState} food={food} setSeq={setSeq} t={t} />
                            </div>
                        </form>
                    )
            }} />
        )
    }

    return (
        <div className="text-center pa20">
            <SlightEmphasisIconNote text={t('no food permission')}/>
            <div className="mt15">
                <Button rounded outlined color="primary" noShadow onClick={() => setSeq(food.defaultSeq())} >{t('Ok')}</Button>
            </div>
        </div>
    )

}

const mapStateToFwProps = state => ({
    trainer: trainerRecordSelector(state),
    user: userRecordSelector(state)
})

const mapDispatchToFwProps = dispatch => ({
    createFoodWeight: data => dispatch(createFoodWeight(data))
})

FoodWeightForm = connect(mapStateToFwProps,mapDispatchToFwProps)(FoodWeightForm)

const SelectForm = ({ food, seq, servings, close, confirmAction, confirmTitle, defaultSeq, servingsChangeHandler, seqChangeHandler, nutritionMultiplier }) => {
    const { t } = useTranslation();
    const foodWeight = food.foodWeightFor(seq);

    return (
        <FormikWrapper initialValues={ { id: food.id, seq, servings, foodWeightId: (foodWeight && foodWeight.id) } }
            validationSchema={Yup.object().shape({
                servings: Yup.number().moreThan(0).required()
            })}
            submit={confirmAction}
            initialErrors={{}} render={({ setValues, handleBlur, handleSubmit, values, errors, submitState }) => {
                const { servingInfo, ...macros } = food.macroHash(seq,servings,nutritionMultiplier);
                return (
                    <form onSubmit={handleSubmit} className="text-center">
                        <div key="chart" className="pt10">

                            <FullSvgMacroChart macros={macros} />

                        </div>
                        <div key="form" className="pa10">
                            <PlusMinusToggle 
                                tighter
                                inline
                                value={values.servings} 
                                setValue={(newVal) => servingsChangeHandler(newVal,values,setValues)}  
                                error={errors.servings} 
                                render={({ value, error, onChange}) => {
                                    return (
                                        <PlusMinusField label=''
                                        onChange={onChange} 
                                        onBlur={handleBlur} 
                                        value={value} 
                                        error={error} 
                                        name="servings"/>
                                    )
                            }} />
                            <SelectInput 
                                collection={food.weightsForForm(defaultSeq,true,t)} 
                                valMethod={1} 
                                textMethod={0}
                                value={values.seq}
                                onChange={(e) => seqChangeHandler(e,values,setValues)}
                                browserDefault 
                                className="small-inline"
                                name="seq" 
                            />
                        </div>
                        <ModalFooter defaults>
                            <SubmitLOC close={close} loadState={submitState} confirmTitle={confirmTitle} />
                        </ModalFooter>
                    </form>
                )
        }} />
    )
}

export class FoodAmountSelectForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { status: "DEFAULT", seq: (props.defaultSeq === null || props.defaultSeq === undefined) ? props.food.defaultSeq() : props.defaultSeq, servings: props.defaultServings || props.food.defaultServings() };
        this.servingsChangeHandler = this.servingsChangeHandler.bind(this);
        this.seqChangeHandler = this.seqChangeHandler.bind(this);
        this.setStatus = this.setStatus.bind(this);
    }

    setSeq = (seq) => this.setState({ seq });

    servingsChangeHandler(newServ,values,setValues) {
        this.setState({ servings: newServ });
        values && setValues({ ...values, servings: newServ });
    }

    seqChangeHandler(e,values,setValues) {
        const newSeq = _.castNumericInput(e.target.value);
        this.setSeq(newSeq);
        let newValues = { ...values, seq: newSeq };
        if(values && !_.isBlank(newSeq)) {
            const foodWeight = this.props.food.foodWeightFor(newSeq);
            if(foodWeight) 
                newValues = { ...newValues, foodWeightId: foodWeight.id };
        }
        values && setValues(newValues);
    }

    selectedFoodWeight = () => this.props.food.foodWeightFor(this.props.seq);

    setStatus(status) {
        this.setState({ status: status });
    }

    render() {
        const { food, confirmTitle, confirmAction, close, defaultSeq, watermark, nutritionMultiplier } = this.props;
        const { seq, servings } = this.state;

        return (
            <React.Fragment>
                <ModalHeader defaults exitButton>
                    {watermark}
                        <b>{food.name}</b>
                </ModalHeader>
                {!_.isBlank(seq) && (<SelectForm 
                    food={food}
                    seq={seq}
                    servings={servings}
                    close={close}
                    confirmAction={confirmAction}
                    confirmTitle={confirmTitle}
                    defaultSeq={defaultSeq}
                    nutritionMultiplier={nutritionMultiplier}
                    servingsChangeHandler={this.servingsChangeHandler}
                    seqChangeHandler={this.seqChangeHandler}
                />)}
                {_.isBlank(seq) && (<FoodWeightForm 
                    food={food}
                    setSeq={this.setSeq}
                />)}
            </React.Fragment>
        )
    }
}

const Success = ({ food, confirmAction, confirmTitle, close, watermark }) => {
    const { t } = useTranslation();

    if(!food) {
        return (
            <React.Fragment>
                <ModalHeader defaults exitButton>
                        {watermark}
                        <b>{t("Food Not Found")}</b>
                </ModalHeader>
                <div className="text-center pa20">
                    <FontAwesomeIcon icon="info-circle" className="info-color" /> 
                    {t("No foods in our database match that barcode.")}
                </div>
                <ModalFooter defaults>
                    <Button rounded color="primary" onClick={() => close()}>{t('Ok')}</Button>
                </ModalFooter>
            </React.Fragment>
        )
    } else {
        const formProps = { food, confirmAction, confirmTitle, close, watermark }
        return (
            <FoodAmountSelectForm {...formProps} />
        )
    }
}

const LoadingContainer = loadingContainer({
    "SUCCESS": Success
},{ type: "padded" })

class ModalContent extends React.Component {
    
    render() {
        const { t, basePath, confirmAction, confirmTitle, barcode, barcodeType, food, getFood, close, scanning, watermark, setScannedParams, setScanning, modalStatus } = this.props;

        if(scanning) {
            if(window.isCordova) {
                return ''
            }
            
            return (
                <div className={"scanner-vid"}>
                    <BarcodeScannerWrapper
                        onUpdate={(err, result) => {
                            if(result && result.format) {
                                setScannedParams({ barcode: result.text, barcodeType: result.format });
                                setScanning(false);
                            }
                        }}
                        stopStream={(modalStatus === 'exiting' || modalStatus === 'exited')}
                    />
                </div>
              );
        } else if(!barcode) {
            if(_.isOldApp()) {
                return (
                    <React.Fragment>
                        <div className="pa20 text-center">
                            <SlightEmphasisIconNote text={t("Please upgrade to the latest version of Strongr Fastr to use barcode scanning")} />
                        </div>
                        <ModalFooter defaults>
                            <Button rounded color="primary" onClick={() => close()}>{t('Ok')}</Button>
                        </ModalFooter>
                    </React.Fragment>
                )
            } else {
                return (
                    <div className="pa30 text-center">
                        <Redirect to={basePath} />
                    </div>
                )
            }
        } else {
            return (
                <LoadingContainerParent 
                    component={LoadingContainer}
                    load={getFood.bind(this,{ barcode, barcodeType })}
                    preloaded={() => !!food}
                    food={food}
                    confirmAction={confirmAction}
                    confirmTitle={confirmTitle}
                    close={close}
                    watermark={watermark}
                />
            )
        }
    }
}

const mapStateToProps = state => ({
    food: scannedFoodSelector(state)
})

const mapDispatchToProps = dispatch => ({
    getFood: (params) => dispatch(getFoodFromBarcode(params)),
    clearScannedFood: () => dispatch(clearScannedFood())
})

const ScannedFoodLogModal = ({ basePath, confirmAction, confirmTitle, clearScannedFood, getFood, food, render }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [scanning,setScanning] = useState(false);
    const [scannedParams,setScannedParams] = useState({});
    let launchHandler;
    if(_.isOldApp()) {
        launchHandler = () => history.push(basePath + scannedFoodLogModalPostfix);
    } else if(!(window.isCordova || _.isPWA())) {
        launchHandler = () => history.push(getAppPath);
    } else {
        launchHandler = launchBarcodeScanner({ setScanning, setScannedParams, basePath, modalPostfix: scannedFoodLogModalPostfix, clearScannedFood, t, history });
    }


    return (
        <React.Fragment>
            {render && render({ launchHandler })}
            <RouteModal fullWidth fixed path={basePath + scannedFoodLogModalPostfix} exact render={(modalProps) => (
                <ModalContent 
                    {...modalProps} 
                    getFood={getFood}
                    food={food}
                    confirmAction={confirmAction} 
                    confirmTitle={confirmTitle}
                    setScannedParams={setScannedParams}
                    {...scannedParams} 
                    watermark={<FatsecretWatermark side={'left'} />}
                    t={t}
                    setScanning={setScanning}
                    scanning={scanning}
                    basePath={basePath}
                />
            )} />
        </React.Fragment>
    )
}

const ConnectedModal = connect(mapStateToProps,mapDispatchToProps)(ScannedFoodLogModal);

export default ConnectedModal;