import React from 'react';
import * as _ from 'lib/utilities';
import * as api from 'lib/api';

export default class AndroidStepCounterPrompt extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props;
        if(_.hasStepCounter() && !user.showNutritionOnly()) {
            if(!!window.stepcounter.start) {
                window.stepcounter.start(api.androidStepsUpdateUrl());
            }
        }
    }

    render() {
        return '';
    }
}